import './../styles/views/Messages.scss';

import React, { useEffect, useState } from 'react';
import { BiEdit } from 'react-icons/bi';
import { useHistory, useParams } from 'react-router';
import { useRecoilValue } from 'recoil';
import useSWR, { mutate } from 'swr';

import { ChatCard } from '../components/feature/chat/ChatCard';
import { NewChatDialog } from '../components/feature/chat/NewChatDialog';
import { SelectedChat } from '../components/feature/chat/SelectedChat';
import { useConSnackbar } from '../hooks/useConSnackbar';
import { ActiveChat } from '../models/chat/ActiveChat';
import { RecentChat } from '../models/chat/RecentChat';
import { UniqueIdentifier } from '../models/UserModel';
import { loggedInUID } from '../state/selectors/UserSelectors';
import {
  removeMessageListener,
  sendMessage,
  setMessageListener,
} from '../utils/chatUtils';
import {
  ParseUID,
  UIDToString,
} from '../utils/helpers/UniqueIdentifier.helper';
import { initUserApi } from '../utils/userUtils';

type ParamTypes = {
  displayName: string;
  tag: string;
};

export default function Messages() {
  const { displayName, tag } = useParams<ParamTypes>();

  const activeChatLogUrl = `${process.env.REACT_APP_API_ENDPOINT_CHAT}chat/logs/${displayName}/${tag}`;
  const recentChatsUrl = `${process.env.REACT_APP_API_ENDPOINT_CHAT}chat/recentChats`;

  const uniqueIdentifier = useRecoilValue(loggedInUID);
  const [newMessageDialogOpen, setNewMessageDialogOpen] = useState(false);
  const { data: recentChats, error: recentChatsError } = useSWR<RecentChat[]>(
    recentChatsUrl
  );
  const { data: activeChat, error: activeChatError } = useSWR<ActiveChat>(
    // Only update the active chat if an active chat is selected
    displayName != undefined && tag != undefined ? activeChatLogUrl : null,
    { refreshInterval: 500 }
  );
  const history = useHistory();
  const [enque] = useConSnackbar();

  const messageReceived = (sender: string, message: string) => {
    // Update the recent chats so the order and mostRecentMessage is right
    mutate(recentChatsUrl);

    // If the user has not been set or the user is sending a message to themselves, do not set the message in the chat
    if (uniqueIdentifier == null || UIDToString(uniqueIdentifier) == sender)
      return;

    // Also updates the current chat
    // Note, this also happens if the message received is not from the current chat
    mutate(
      `${process.env.REACT_APP_API_ENDPOINT_CHAT}chat/logs/${displayName}/${tag}`
    );
    mutate(
      `${process.env.REACT_APP_API_ENDPOINT_CHAT}chat/logs/${displayName}/${tag}`
    );
    mutate(activeChatLogUrl, {
      messages: [
        ...(activeChat?.messages ?? []),
        {
          content: message,
          sender: ParseUID(sender),
          createdAt: Date.now(),
        },
      ],
      chattingWith: activeChat?.chattingWith,
    });
  };

  useEffect(() => {
    mutate(`${process.env.REACT_APP_API_ENDPOINT_CHAT}chat/recentChats`);
  }, [displayName, tag]);

  // Called when a message is sent
  const handleSendMessage = (
    e: any,
    content: string,
    recipient?: UniqueIdentifier
  ) => {
    e.preventDefault();
    // If message is empty or consists of only whitespace, do not send it
    if (content == null || content.trim() === '') {
      return;
    }
    sendMessage(content, UIDToString(uniqueIdentifier), UIDToString(recipient))
      .then(() => {
        // Prepend the new message to the list of messages for better responsiveness
        // SWR will validate this afterwards
        mutate(activeChatLogUrl, {
          messages: [
            ...(activeChat?.messages ?? []),
            {
              content: content,
              sender: uniqueIdentifier,
              createdAt: Date.now(),
            },
          ],
          chattingWith: activeChat?.chattingWith,
        });

        // Also update the recentChats so ordering is proper and mostRecentMessage is updated
        mutate(recentChatsUrl);
      })
      .catch((err) => {
        return console.error(err.toString());
      });
  };

  useEffect(() => {
    // Sets the list of recent chats on init
    mutate(recentChatsUrl).then((res) => {
      // If there are no recent chats, do not set anything
      if (res.length == 0) return;
      if (UIDToString(res[0].username) != `${displayName}#${tag}`) {
        history.push(
          `/messages/${res[0].username.displayName}/${res[0].username.tag}`
        );
      }
    });

    // Adds a listener that updates the chat when a message is received
    setMessageListener(messageReceived);

    // Removes above listener when navigating away from the chat page
    return () => {
      removeMessageListener(messageReceived);
    };
  }, []);

  // If the active chat user changes, update the active chat log
  useEffect(() => {
    if (displayName == undefined || tag == undefined) return;
    // Check if the selected user does not appear in the list of recent chats
    // This happens when writing a message to a user with no chat history
    if (
      recentChats?.find((rc) => {
        return UIDToString(rc.username) == `${displayName}#${tag}`;
      }) == undefined
    ) {
      // Gets the data of the user to prepend to the RecentChats list
      initUserApi()
        .get(`user/${displayName}/${tag}`)
        .then((res) => {
          // Creates an object that fits the RecentChats model from the user data
          const recentChat = {
            username: res.data.uniqueIdentifier,
            imageUrl: res.data.imageUrl,
            mostRecentMessage: undefined,
          };

          // If a list of recent chats already exists, prepend the new chat object to it
          const newRecentChats =
            recentChats != undefined
              ? [recentChat, ...recentChats]
              : [recentChat];
          mutate(recentChatsUrl, newRecentChats, false);
        })
        .catch((err) => {
          console.log(err);
        });
    }
    // Updates the currently selected chat log
    mutate(activeChatLogUrl);
  }, [displayName, tag]);

  const handleNewChat = (receiver?: UniqueIdentifier) => {
    // Close the dialog window
    setNewMessageDialogOpen(false);

    // Check if the user is attempting to start a chat with themselves
    if (
      receiver?.displayName.toLowerCase() ==
        uniqueIdentifier?.displayName.toLowerCase() &&
      receiver?.tag == uniqueIdentifier?.tag
    ) {
      history.push(
        `/messages/${uniqueIdentifier?.displayName}/${uniqueIdentifier?.tag}`
      );
      return;
    }

    // Fetches a list of mutual followers to ensure that the user only chats with eligible other users
    initUserApi()
      .get<{ uniqueIdentifier: UniqueIdentifier }[]>('user/mutualFollowers')
      .then((res) => {
        // Checks if the requested user to chat with is a mutual follower
        const user = res.data.find(
          (u) =>
            receiver?.displayName.toLowerCase() ==
              u.uniqueIdentifier.displayName.toLowerCase() &&
            receiver?.tag == u.uniqueIdentifier.tag
        );
        // If the user is a mutual follower, redirect to the appropriate path
        // If not, send a toast notifying the user
        user != undefined
          ? history.push(
              `/messages/${user.uniqueIdentifier.displayName}/${user.uniqueIdentifier.tag}`
            )
          : enque({
              message: `User ${receiver?.displayName}#${receiver?.tag} does not exist or is not a mutual follower`,
              variant: 'error',
            });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div className="flex flex-col h-full w-full">
      <div className="flex h-2/12 w-full justify-start">
        <div className="open-chats-container">
          <div
            className="flex flex-col justify-center items-center gap-1"
            onClick={() => setNewMessageDialogOpen(!newMessageDialogOpen)}
          >
            <BiEdit className="w-10 h-10 rounded-full border-2 p-2" />
            <p className="text-xs hidden md:flex">New Message</p>
          </div>
          {recentChats?.map((rc) => {
            return (
              <ChatCard
                key={UIDToString(rc.username)}
                imageUrl={rc.imageUrl}
                uid={rc.username}
                mostRecentMessage={rc.mostRecentMessage}
                selected={UIDToString(rc.username) == `${displayName}#${tag}`}
                onClick={(name: UniqueIdentifier) => {
                  if (UIDToString(name) != `${displayName}#${tag}`) {
                    history.push(`/messages/${name.displayName}/${name.tag}`);
                  }
                }}
              />
            );
          })}
        </div>
        <div className="w-full flex p-2">
          <SelectedChat
            messages={activeChat?.messages}
            chattingWith={ParseUID(`${displayName}#${tag}`)}
            onClickSend={(
              e: any,
              content: string,
              recipient?: UniqueIdentifier
            ) => handleSendMessage(e, content, recipient)}
          />
        </div>
      </div>
      <NewChatDialog
        open={newMessageDialogOpen}
        handleNewChat={handleNewChat}
        handleInvalidInput={() => {
          enque({
            message: `Please input a valid username`,
            variant: 'error',
          });
          setNewMessageDialogOpen(false);
        }}
        handleClose={() => setNewMessageDialogOpen(false)}
      />
    </div>
  );
}
