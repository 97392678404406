import './ConSearchBar.scss';

import React, { FC } from 'react';
import { AiOutlineSearch } from 'react-icons/ai';

export const ConSearchBar: FC = () => {
  return (
    <div className="search-container">
      <AiOutlineSearch className="search-icon" />
      <input
        placeholder="Search"
        className="search-input"
        type="search"
        // onChange={(e) => console.log(e.target.value)}
      />
    </div>
  );
};
