import { useEffect, useState } from 'react';

import { Me } from '../models/UserModel';
import { initUserApi } from '../utils/userUtils';

export const useProfile = () => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [me, setMe] = useState<Me | null>(null);

  useEffect(() => {
    initUserApi()
      .get<Me>(`User/me`)
      .then((res) => {
        setMe(res.data);
        setLoading(false);
      })
      .catch((e) => {
        console.log(e);
        setError(true);
        setLoading(false);
      });
  }, []);

  return { me, error, loading };
};
