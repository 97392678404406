import React, { FC } from 'react';

import { CommentType } from '../../../../models/CommentModel';
import { ConComment } from './ConComment';

type Props = {
  comments: CommentType[];
  postId: string;
  likedComments: string[];
};

export const CommentSection: FC<Props> = ({
  comments,
  postId,
  likedComments,
}) => {
  return (
    <div className="flex flex-col gap-2">
      {comments &&
        comments.map((comment, index) => (
          <ConComment
            key={comment.id + index.toString()}
            comment={comment}
            postId={postId}
            likedComments={likedComments}
          />
        ))}
    </div>
  );
};
