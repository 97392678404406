import React, { FC, useState } from 'react';
import { Link } from 'react-router-dom';

import { CommentType } from '../../../../models/CommentModel';
import { initForumApi } from '../../../../utils/forumUtils';
import { formatDate } from '../../../../utils/utils';
import { CommentActionBar } from './CommentActionBar';
import { CommentSection } from './CommentSection';

type Props = {
  comment: CommentType;
  postId: string;
  likedComments: string[];
};

export const ConComment: FC<Props> = ({ comment, postId, likedComments }) => {
  const [replies, setReplies] = useState(comment.replies);
  const [likedForumComments, setLikedForumComments] = useState(likedComments);
  const [commentLikes, setCommentLikes] = useState(comment.likes);

  const handleReply = (value: string) => {
    initForumApi()
      .post(`/comment/${postId}/${comment.id}`, { content: value })
      .then((res) => {
        setReplies((pre) => [...pre, res.data]);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const handleLike = () => {
    initForumApi()
      .post(`/comment/${postId}/${comment.id}/like`)
      .then(() => {
        if (likedForumComments.includes(comment.id)) {
          setLikedForumComments((prev) =>
            prev.filter((com) => com !== comment.id)
          );
          setCommentLikes((prev) => prev - 1);
        } else {
          setLikedForumComments((prev) => [...prev, comment.id]);
          setCommentLikes((prev) => prev + 1);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  return (
    <div className="flex w-full gap-3 my-2">
      <div className="w-full px-5">
        <div className="flex gap-3">
          <Link to={`/u/${comment.author.displayName}/${comment.author.tag}`}>
            <p className="text-xs text-light-default text-opacity-80">
              {`u/${comment.author.displayName}#${comment.author.tag}`}
            </p>
          </Link>
          <p className="text-xs text-light-default text-opacity-80">
            {formatDate(comment?.createdAt)}
          </p>
        </div>
        <div className="border-l border-light-default border-opacity-20 mt-2">
          <p className="text-xs px-2">{comment?.content}</p>
          <CommentActionBar
            likes={commentLikes}
            handleAddComment={handleReply}
            isLiked={likedForumComments.includes(comment.id)}
            handleLikeComment={handleLike}
          />
          <CommentSection
            comments={replies}
            postId={postId}
            likedComments={likedForumComments}
          />
        </div>
      </div>
    </div>
  );
};
