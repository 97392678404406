import clsx from 'clsx';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { BsPlusSquare } from 'react-icons/bs';
import { useHistory, useParams } from 'react-router-dom';
import useSWR, { mutate } from 'swr';

import { CreateForumPostDialog } from '../components/feature/forum/CreateForumPostDialog';
import { PostCard } from '../components/feature/forum/PostCard/PostCard';
import LoadingSpinner from '../components/feature/shared/LoadingSpinner/LoadingSpinner';
import { ShareDialog } from '../components/feature/sharing/ShareDialog';
import { useConSnackbar } from '../hooks/useConSnackbar';
import { useSpecificForumPosts } from '../hooks/useSpecificForumPosts';
import { ForumDetailed } from '../models/ForumModel';
import { Post } from '../models/PostModel';
import { Me } from '../models/UserModel';
import { initForumApi } from '../utils/forumUtils';

type ParamTypes = {
  id: string;
};

export const SpecificForum = () => {
  const { id } = useParams<ParamTypes>();
  const [pageNumber, setPageNumber] = useState(1);
  const [choice, setChoice] = useState('popular');
  const [dialogOpen, setDialogOpen] = useState(false);
  const [enque] = useConSnackbar();
  const history = useHistory();
  const [shareDialogOpen, setShareDialogOpen] = useState(false);
  const [postToShare, setPostToShare] = useState<Post | undefined>();

  const { data, error: forumError } = useSWR<ForumDetailed>(
    `${process.env.REACT_APP_API_ENDPOINT_FORUM}forum/${id}/detailed`
  );

  const { data: me, error: meError } = useSWR<Me>(
    `${process.env.REACT_APP_API_ENDPOINT_USER}user/me`
  );

  const { loading, error, posts, hasMore } = useSpecificForumPosts(
    id,
    pageNumber,
    choice
  );

  useEffect(() => {
    setPageNumber(1);
  }, [id, choice]);

  const handleDialogOpen = () => {
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const onSharePost = (
    imageList: { imageUrl: string }[],
    content: string,
    title: string
  ) => {
    const list = imageList.filter((url) => url.imageUrl != '');
    initForumApi()
      .post(`/post/${id}`, {
        title: title,
        content: content,
        imageUrls: list,
      })
      .then((res) => {
        handleDialogClose();
        enque({
          message: 'Your post was added!',
          variant: 'success',
        });
        history.push(`/forum/post/${res.data.id}`);
      })
      .catch((e) => console.log(e));
  };

  const toggleFollowForum = () => {
    initForumApi()
      .post(`forum/${data?.id}/follow`)
      .then(() => {
        mutate(
          `${process.env.REACT_APP_API_ENDPOINT_FORUM}forum/${id}/detailed`
        );
        mutate(`${process.env.REACT_APP_API_ENDPOINT_USER}user/me`);
      });
  };

  const onPostLike = (id: string) => {
    initForumApi()
      .post(`/post/${id}/like`)
      .then(() => {
        mutate(`${process.env.REACT_APP_API_ENDPOINT_USER}user/me`);
      });
  };

  /*
    next 20ish lines of code creates the infinitForumApiy scroll feeling.
  */
  const observer = useRef<IntersectionObserver>();
  const lastPostElementRef = useCallback(
    (node) => {
      if (loading) return;
      if (observer.current) {
        observer.current;
      }

      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setPageNumber((prevPageNumber) => prevPageNumber + 1);
        }
      });

      if (node) {
        observer.current.observe(node);
      }
    },
    [loading, hasMore]
  );

  return (
    <div className="w-full flex flex-col gap-4">
      {!data && <div>Could not find the forum you were looking for.</div>}
      {data && (
        <div className="w-full flex flex-col items-center justify-center gap-4">
          <div className="relative w-full">
            <img
              src={data?.imageUrl}
              alt=""
              className="w-full max-h-44 lg:max-h-80 h-auto object-cover rounded-md"
            />
            <img
              src={data?.iconUrl}
              alt=""
              className="w-32 h-32 md:w-48 md:h-48 xl:w-56 xl:h-56 rounded-full border-8 border-primary-default absolute top-32 md:top-20 lg:top-56 xl:top-48 left-3 xl:left-10"
            />
            <div className="md:flex flex-wrap gap-2 justify-center items-center hidden absolute bottom-2 right-2">
              {data.tags.map((tag) => {
                return (
                  <div
                    className="rounded-xl bg-primary-light bg-opacity-50 p-2 flex justify-center items-center h-8"
                    key={tag}
                  >
                    <p className="text-xs">{tag}</p>
                  </div>
                );
              })}
            </div>
          </div>

          <div className="w-full flex flex-col gap-3 pb-10">
            <div className="w-full flex flex-col gap-3 h-16 md:h-20 justify-between">
              <div
                className="w-full flex justify-end px-5"
                onClick={toggleFollowForum}
              >
                <div
                  className={clsx(
                    'flex justify-center items-center rounded-md border-secondary-default border px-5 w-28 cursor-pointer',
                    {
                      'bg-secondary-default text-primary-default': !me?.followedForums.includes(
                        data.id
                      ),
                    }
                  )}
                >
                  <p className="text-md">
                    {me?.followedForums.includes(data.id)
                      ? 'unfollow'
                      : 'follow'}
                  </p>
                </div>
              </div>
              <div className="flex w-full justify-end px-5">
                <div
                  className="flex gap-2 justify-center items-center cursor-pointer"
                  onClick={handleDialogOpen}
                >
                  <p className="text-xs">Create post</p>
                  <BsPlusSquare className="text-2xl" />
                </div>
                <CreateForumPostDialog
                  handleClose={handleDialogClose}
                  open={dialogOpen}
                  me={me ?? null}
                  handleSharePost={onSharePost}
                />
              </div>
            </div>

            <div className="w-full px-5  text-sm">
              <div className="flex items-end gap-3">
                <p className="text-xl md:text-2xl to-secondary-default">
                  {data?.name}
                </p>
                <p className="text-xs text-opacity-75 text-light-default">
                  {data?.numOfSubscribers} followers
                </p>
              </div>
              <p className="text-md text-light-default">{data?.description}</p>
            </div>
          </div>

          <div className="w-full flex flex-col justify-center items-center gap-2">
            <div className="flex w-11/12 lg:w-4/12 justify-around border-secondary-default border-opacity-75 border rounded-lg">
              <div
                className={`${
                  choice === 'new'
                    ? 'bg-secondary-default text-primary-default w-1/2 text-center rounded-md'
                    : 'w-1/2 text-center'
                }`}
                onClick={() => setChoice('new')}
              >
                New
              </div>
              <div
                className={`${
                  choice === 'popular'
                    ? 'bg-secondary-default text-primary-default w-1/2 text-center rounded-md'
                    : 'w-1/2 text-center'
                }`}
                onClick={() => setChoice('popular')}
              >
                Popular
              </div>
            </div>
            <p className="text-xs">Sort posts by</p>
          </div>

          <div className="flex flex-col w-full md:w-4/5 lg:w-full xl:w-4/5">
            {posts?.map((post, index) => {
              if (posts.length === index + 1) {
                return (
                  <PostCard
                    key={post.id}
                    lastElRef={lastPostElementRef}
                    post={post}
                    isLikingPost={
                      me?.likedForumPosts.includes(post.id) ?? false
                    }
                    onLikePost={onPostLike}
                    onClickShare={() => {
                      setPostToShare(post);
                      setShareDialogOpen(true);
                    }}
                  />
                );
              } else {
                return (
                  <PostCard
                    post={post}
                    key={post.id}
                    isLikingPost={
                      me?.likedForumPosts.includes(post.id) ?? false
                    }
                    onLikePost={onPostLike}
                    onClickShare={() => {
                      setPostToShare(post);
                      setShareDialogOpen(true);
                    }}
                  />
                );
              }
            })}
            <ShareDialog
              open={shareDialogOpen}
              content={
                'Wow look at this cool post i found on connectsocial.online\n\n'
              }
              // Has to code URL like this since the current url is /profile and not /displayname/tag
              shareUrl={`https://connectsocial.online/forum/post/${
                postToShare?.id ?? ''
              }`}
              handleClose={() => setShareDialogOpen(false)}
            />
            <div className="flex w-full justify-center items-center">
              {loading && <LoadingSpinner />}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
