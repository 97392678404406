import './Dropdown.scss';

import { Typography } from '@material-ui/core';
import React, { FC, useState } from 'react';
import { AiOutlineLeft, AiOutlineRight } from 'react-icons/ai';
import { FaRegUser } from 'react-icons/fa';
import { FiLogOut, FiSettings } from 'react-icons/fi';
import { Link, useHistory } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';
import { useRecoilState } from 'recoil';
import useSWR from 'swr';

import { Me } from '../../../../../models/UserModel';
import {
  isLoggedInState,
  profileNavMenuState,
  userInfoState,
} from '../../../../../state/atoms/atoms';
import { logout } from '../../../../../utils/authUtils';

type ItemProps = {
  leftIcon?: any;
  rightIcon?: any;
  goToMenu?: string;
  onClick?: () => void;
};

export const Dropdown: FC = () => {
  const [activeMenu, setAactiveMenu] = useState('main'); // settings
  const [menuHeight, setMenuHeight] = useState();
  const [isLoggedIn, setIsLoggedIn] = useRecoilState(isLoggedInState);
  const [userInfo, setuserInfo] = useRecoilState(userInfoState);
  const [open, setOpen] = useRecoilState(profileNavMenuState);

  const history = useHistory();
  const { data, error } = useSWR<Me>(
    `${process.env.REACT_APP_API_ENDPOINT_USER}User/me`
  );

  const calcHeight = (el: any) => {
    const height = el.offsetHeight;
    setMenuHeight(height + 34);
  };

  const signout = () => {
    logout();
    setIsLoggedIn(false);
    setuserInfo(null);
    history.push('/');
  };

  const DropdownItem: FC<ItemProps> = ({
    children,
    leftIcon,
    rightIcon,
    goToMenu,
    onClick,
  }) => {
    return (
      <a
        className="dropdown-menu-item"
        onClick={() => {
          goToMenu && setAactiveMenu(goToMenu);
          onClick && onClick();
        }}
      >
        <span className="dropdown-icon-button">{leftIcon}</span>
        {children}
        <span className="icon-right dropdown-icon-button">{rightIcon}</span>
      </a>
    );
  };

  return (
    <div className="dropdown" style={{ height: menuHeight }}>
      <CSSTransition
        in={activeMenu === 'main'}
        unmountOnExit
        timeout={500}
        classNames="menu-primary"
        onEnter={calcHeight}
      >
        <div className="menu">
          <Link to="/profile" onClick={() => setOpen(false)}>
            <DropdownItem leftIcon={<FaRegUser />}>
              {`${data?.firstName}` ?? 'profile'}
            </DropdownItem>
          </Link>
          <DropdownItem
            leftIcon={<FiSettings />}
            rightIcon={<AiOutlineRight />}
            goToMenu="settings"
          >
            Settings
          </DropdownItem>
          <DropdownItem leftIcon={<FiLogOut />} onClick={signout}>
            Logout
          </DropdownItem>
        </div>
      </CSSTransition>
      <CSSTransition
        in={activeMenu === 'settings'}
        unmountOnExit
        timeout={500}
        classNames="menu-secondary"
        onEnter={calcHeight}
      >
        <div className="settings">
          <DropdownItem leftIcon={<AiOutlineLeft />} goToMenu="main">
            <Typography variant="h6">Settings</Typography>
          </DropdownItem>
          <Link to="/user/setup" onClick={() => setOpen(false)}>
            <DropdownItem leftIcon={<FiSettings />}>Profile info</DropdownItem>
          </Link>
          {/* <DropdownItem leftIcon={<FiSettings />}>Settings</DropdownItem> */}
          {/* <DropdownItem leftIcon={<FiSettings />}>Settings</DropdownItem> */}
        </div>
      </CSSTransition>
    </div>
  );
};
