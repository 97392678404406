import { UniqueIdentifier } from '../../models/UserModel';

export const CompareUID = (
  uid1?: UniqueIdentifier,
  uid2?: UniqueIdentifier
): boolean => {
  return uid1?.displayName == uid2?.displayName && uid1?.tag === uid2?.tag;
};

export const UIDToString = (uid?: UniqueIdentifier): string => {
  return `${uid?.displayName}#${uid?.tag}`;
};

export const ParseUID = (uidString?: string): UniqueIdentifier | undefined => {
  const strSplit = uidString?.split('#');
  if (
    strSplit == undefined ||
    strSplit[0] == undefined ||
    isNaN(parseInt(strSplit[1]))
  )
    return;
  return { displayName: strSplit[0], tag: parseInt(strSplit[1]) };
};
