import React, { FC } from 'react';
import { useParams } from 'react-router';
import { NavLink } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import useSWR from 'swr';

import LoadingSpinner from '../components/feature/shared/LoadingSpinner/LoadingSpinner';
import { FollowerUserCard } from '../components/feature/user/FollowerUserCard';
import { useFollowinfo } from '../hooks/useFollowinfo';
import { Me } from '../models/UserModel';
type ParamTypes = {
  followInfo: string;
  displayName: string;
  tag: string;
};

interface stateType {
  id: string;
}

type Props = {
  me?: Me;
};

export const UserFollower: FC<Props> = ({ me }) => {
  const { displayName, tag, followInfo } = useParams<ParamTypes>();
  const { state } = useLocation<stateType>();

  const { data: user, error: userError } = useSWR<Me>(
    `${process.env.REACT_APP_API_ENDPOINT_USER}User/${displayName}/${tag}`
  );

  const {
    users: followers,
    error: followersError,
    isLoadingMore: followersIsLoadingMore,
    size: followersSize,
    setSize: followersSetSize,
    isReachingEnd: followersIsReachingEnd,
  } = useFollowinfo(state.id, 'followers');

  const {
    users: following,
    error: followingError,
    isLoadingMore: followingIsLoadingMore,
    size: followingSize,
    setSize: followingSetSize,
    isReachingEnd: followingIsReachingEnd,
  } = useFollowinfo(state.id, 'followedUsers');

  return (
    <div className="w-full bg-primary-light bg-opacity-30 rounded-md">
      <div className="flex justify-evenly">
        <NavLink
          to={{
            pathname: `/followers/${displayName}/${tag}`,
            state: { id: state.id },
          }}
          className="w-full flex justify-center items-center border-primary-light border-b h-16 transition delay-150 duration-300 ease-in-out"
          activeClassName="border-secondary-default"
        >
          Followers ({user?.numOfFollowers})
        </NavLink>
        <NavLink
          to={{
            pathname: `/following/${displayName}/${tag}`,
            state: { id: state.id },
          }}
          className="w-full flex justify-center items-center border-primary-light border-b h-16 transition delay-150 duration-300 ease-in-out"
          activeClassName="border-secondary-default"
        >
          Following ({user?.numOfFollowing})
        </NavLink>
      </div>
      {followInfo === 'followers' && (
        <div className="flex flex-col gap-3 ">
          {followers.map((user) => (
            <FollowerUserCard user={user} />
          ))}
          <button
            disabled={followersIsLoadingMore || followersIsReachingEnd}
            onClick={() => followersSetSize(followersSize + 1)}
            className="w-full cursor-pointer h-16 flex justify-center items-center border-secondary-default border-t border-opacity-50"
          >
            {followersIsLoadingMore ? (
              <LoadingSpinner />
            ) : followersIsReachingEnd ? (
              'No more'
            ) : (
              'Load more'
            )}
          </button>
        </div>
      )}
      {followInfo === 'following' && (
        <div className="flex flex-col gap-3 ">
          {following.map((user) => (
            <FollowerUserCard user={user} />
          ))}
          <button
            disabled={followingIsLoadingMore || followingIsReachingEnd}
            onClick={() => followingSetSize(followingSize + 1)}
            className="w-full cursor-pointer h-16 flex justify-center items-center border-secondary-default border-t border-opacity-50"
          >
            {followingIsLoadingMore ? (
              <LoadingSpinner />
            ) : followingIsReachingEnd ? (
              'No more'
            ) : (
              'Load more'
            )}
          </button>
        </div>
      )}
    </div>
  );
};
