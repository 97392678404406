import './PostCard.scss';

import { Typography } from '@material-ui/core';
import React, { FC } from 'react';
import { AiFillHeart, AiOutlineHeart } from 'react-icons/ai';
import { BiShare } from 'react-icons/bi';
import { VscComment } from 'react-icons/vsc';
import { Link } from 'react-router-dom';

import { Post } from '../../../../models/PostModel';
import { formatDate } from '../../../../utils/utils';

type Props = {
  post: Post;
  lastElRef?: any;
  isLikingPost: boolean;
  onLikePost: (id: string) => void;
  onClickShare: () => void;
};

export const PostCard: FC<Props> = ({
  post,
  lastElRef,
  isLikingPost,
  onLikePost,
  onClickShare,
}) => {
  return (
    <div className="postcard-container" ref={lastElRef}>
      <div className="flex items-center w-full justify-between">
        <div className="flex">
          <Link to={`/forum/${post.forumId}`}>
            <img
              src={post.forumIcon}
              alt=""
              className="w-10 h-10 rounded-full"
            />
          </Link>
          <div className="flex flex-col pl-3 gap-1">
            <Link to={`/forum/${post.forumId}`}>
              <p className="text-xs text-secondary-default text-opacity-90 hover:text-opacity-100">
                {`c/${post.forumName} - ${formatDate(post.createdAt)}`}
              </p>
            </Link>
            <Link to={`/u/${post.author.displayName}/${post.author.tag}`}>
              <p className="text-xs text-secondary-default text-opacity-50 hover:text-opacity-90">
                {` u/${post.author.displayName}#${post.author.tag}`}
              </p>
            </Link>
          </div>
        </div>
      </div>

      <Link to={`/forum/post/${post.id}`}>
        <div className="">
          <Typography variant="subtitle1">{post.title}</Typography>
          <p className="post-content">{post.content}</p>
        </div>
        <div className="images-container">
          {post.imageUrls.map((image, index) => {
            return (
              image && (
                <img
                  src={image}
                  alt=""
                  className="rounded-md w-full"
                  key={index}
                />
              )
            );
          })}
        </div>
      </Link>

      <div className="w-full flex flex-row justify-between items-center px-5">
        <div
          className="flex justify-center items-center gap-2 cursor-pointer hover:bg-primary-default p-2 rounded-md"
          onClick={() => onLikePost(post.id)}
        >
          {isLikingPost ? (
            <AiFillHeart className="text-lg" />
          ) : (
            <AiOutlineHeart className="text-lg" />
          )}
        </div>
        <Link to={`/forum/post/${post.id}`}>
          <div className="flex justify-center items-center gap-2 cursor-pointer hover:bg-primary-default p-2 rounded-md">
            <p>{post.numOfComments}</p>
            <VscComment className="text-1xl" />
          </div>
        </Link>
        <div
          className="flex justify-center items-center gap-2 cursor-pointer hover:bg-primary-default p-2 rounded-md"
          onClick={onClickShare}
        >
          <BiShare className="text-1xl" />
        </div>
      </div>
    </div>
  );
};
