import { useEffect, useState } from 'react';

import { ForumSearch } from '../models/ForumModel';
import { PostSearch } from '../models/PostModel';
import { initForumApi } from '../utils/forumUtils';

export const useForumPostSearch = (
  searchValue: string,
  pageNumber: number,
  pageSize: number,
  //   tags?: string[],
  searchForums: boolean,
  searchPosts: boolean
) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [posts, setPosts] = useState<PostSearch[] | null>(null);
  const [forums, setForums] = useState<ForumSearch[] | null>(null);
  const [hasMore, setHasMore] = useState(false);

  useEffect(() => {
    setPosts(null);
    setForums(null);
  }, [searchValue]);

  useEffect(() => {
    setLoading(true);
    setError(false);

    if (searchValue === '' || searchValue.length <= 0) {
      setPosts(null);
      setForums(null);
      return;
    }
    if (searchForums) {
      initForumApi()
        .get<ForumSearch[]>(`/forum/search/${pageNumber}`, {
          params: {
            searchValue: searchValue,
            pageSize: pageSize,
          },
        })
        .then((res) => {
          setForums(res.data);
          setLoading(false);
        })
        .catch((e) => {
          console.log(e);
          setError(true);
          setLoading(false);
        });
    } else {
      setForums(null);
    }

    if (searchPosts) {
      initForumApi()
        .get<PostSearch[]>(`/post/TitleContentSearch/${pageNumber}`, {
          params: {
            searchValue: searchValue,
            pageSize: pageSize,
          },
        })
        .then((res) => {
          setPosts(res.data);
          setLoading(false);
        })
        .catch((e) => {
          console.log(e);
          setError(true);
          setLoading(false);
        });
    } else {
      setPosts(null);
    }
  }, [pageNumber, searchValue, searchForums, searchPosts]);

  return { loading, error, posts, forums, hasMore };
};
