import jwt_decode from 'jwt-decode';
import { useEffect } from 'react';
import { useHistory } from 'react-router';
import { useRecoilState } from 'recoil';

import { Me } from '../models/UserModel';
import { isLoggedInState, userInfoState } from '../state/atoms/atoms';
import { DecodedTokenType, initAuthApi } from '../utils/authUtils';
import { buildSignalRConnection, setMessageListener } from '../utils/chatUtils';
import { initUserApi } from '../utils/userUtils';
import { useConSnackbar } from './useConSnackbar';

export const useLogin = () => {
  const [isLoggedIn, setIsLoggedIn] = useRecoilState(isLoggedInState);
  const [userInfo, setUserInfo] = useRecoilState(userInfoState);
  const history = useHistory();
  const [enque] = useConSnackbar();

  useEffect(() => {
    const params = new URLSearchParams(window.location?.search);
    const tokenFromParam = params.get('token');
    const returnRoute = params.get('returnRoute');

    if (tokenFromParam || tokenFromParam !== null) {
      localStorage.setItem('con_token', tokenFromParam);
      const decoded: DecodedTokenType = jwt_decode(tokenFromParam);

      initUserApi()
        .get<Me>(`User/me`)
        .then((res) => {
          localStorage.setItem('con_userIsSetup', res.data.isSetup ? '1' : '0');
          setUserInfo(res.data);
        })
        .catch((e) => {
          console.log(e);
        });

      localStorage.setItem('con_user', decoded.name ?? ''); // fix?? delete?
      setIsLoggedIn(true);
    }

    if (localStorage.getItem('con_token')) {
      const token: string | null = localStorage.getItem('con_token');
      const decoded: DecodedTokenType = jwt_decode(token ?? '');
      if (decoded.exp > new Date().getTime() / 1000) {
        setIsLoggedIn(true);
      } else {
        initAuthApi()
          .get('/Refresh', { withCredentials: true })
          .then((res) => {
            localStorage.setItem('con_token', res.data);
            return true;
          })
          .catch((e) => {
            console.log(e);
            initAuthApi()
              .get('Logout')
              .then(() => {
                setIsLoggedIn(false);
                setUserInfo(null);
                history.push('/');
              });
          });
      }

      buildSignalRConnection().then(() => {
        setMessageListener((sender: string, message: string) => {
          enque({
            message: `${sender}: ${message}`,
            variant: 'default',
          });
        });
      });
    }

    if (returnRoute) {
      history.push(returnRoute);
    }
  }, []);
};
