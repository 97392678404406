import React, { FC, useEffect } from 'react';
import useSWR from 'swr';

import { Me } from '../../../../models/UserModel';
import { MutualFollowers } from '../../home/MutualFollowers/MutualFollowers';
import { ProfileCard } from '../../home/ProfileCard';
import { PopularForums } from '../PopularForums/PopularForums';

export const BasicLayout: FC = ({ children }) => {
  const { data: me, error: meError } = useSWR<Me>(
    `${process.env.REACT_APP_API_ENDPOINT_USER}user/me`
  );

  useEffect(() => {
    // console.log('me:', me);
    // console.log('meErroe:', meError);
  }, [me, meError]);
  return (
    <div className="grid grid-cols-1 lg:grid-cols-feed gap-4 w-full px-4">
      <div className="w-full sticky hidden lg:flex flex-col gap-4">
        <MutualFollowers />
      </div>
      <div className="overflow-y-scroll max-h-screenContainer w-full">
        {children}
      </div>
      <div className="w-full sticky hidden lg:flex flex-col gap-4">
        <ProfileCard me={me ?? null} />
        <PopularForums />
      </div>
    </div>
  );
};
