import './ConHeader.scss';

import { Typography } from '@material-ui/core';
import React, { FC } from 'react';
import { AiOutlineMenu } from 'react-icons/ai';
import { Link } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import useSWR from 'swr';

import { Me } from '../../../../models/UserModel';
import {
  isLoggedInState,
  sideBarShownState,
  userInfoState,
} from '../../../../state/atoms/atoms';
import { ConLink } from '../../home/ConLink/ConLink';
import { ConButton } from '../../shared/ConButton/ConButton';
import { Dropdown } from '../../shared/Navigation/Dropdown/Dropdown';
import { NavItem } from '../../shared/Navigation/NavItem/NavItem';

export const ConHeader: FC = ({}) => {
  const [showMenu, setShowMenu] = useRecoilState(sideBarShownState);
  const [isLoggedIn, setIsLoggedIn] = useRecoilState(isLoggedInState);
  const [userInfo, setUserInfo] = useRecoilState(userInfoState);
  const { data: me, error: meError } = useSWR<Me>(
    `${process.env.REACT_APP_API_ENDPOINT_USER}User/me`
  );

  return (
    <>
      <div className="navbar">
        <Link
          to="#"
          className="open-menu-btn"
          onClick={() => {
            setShowMenu(true);
          }}
        >
          <AiOutlineMenu className="icon" />
        </Link>
        <div className="header-title-container">
          <Typography className="header-title" variant="h4">
            Connect
          </Typography>
        </div>
        {!isLoggedIn && (
          <div className="new-user-actions-container">
            <ConLink variant="Link" to="/register">
              <ConButton outlined={true} text="Create Account" />
            </ConLink>
            <ConLink variant="Link" to="/login">
              <ConButton text="Sign in" />
            </ConLink>
          </div>
        )}
        {isLoggedIn && (
          <div className="user-actions-container">
            <ul>
              <NavItem icon={userInfo?.imageUrl}>
                <Dropdown></Dropdown>
              </NavItem>
            </ul>
          </div>
        )}
      </div>
    </>
  );
};
