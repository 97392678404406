import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import { FaGlobeAmericas } from 'react-icons/fa';
import { GoCalendar } from 'react-icons/go';
import { RiUserFollowLine, RiUserUnfollowFill } from 'react-icons/ri';
import { useHistory, useParams } from 'react-router';
import { Link } from 'react-router-dom';
import useSWR, { mutate } from 'swr';

import { Card } from '../components/feature/shared/Card';
import LoadingSpinner from '../components/feature/shared/LoadingSpinner/LoadingSpinner';
import { ShareDialog } from '../components/feature/sharing/ShareDialog';
import { UserPostCard } from '../components/feature/user/UserPostCard/UserPostCard';
import { useFetchUserPosts } from '../hooks/useFetchUserPosts';
import { Me } from '../models/UserModel';
import { UserPost } from '../models/UserPostModel';
import { initUserApi } from '../utils/userUtils';
import { formatJoinedAt } from '../utils/utils';

type ParamTypes = {
  displayName: string;
  tag: string;
};

export const UserProfile: FC = () => {
  const history = useHistory();
  const { displayName, tag } = useParams<ParamTypes>();
  const { data: user, error } = useSWR<Me>(
    `${process.env.REACT_APP_API_ENDPOINT_USER}User/${displayName}/${tag}`
  );
  const { data: me, error: meError } = useSWR<Me>(
    `${process.env.REACT_APP_API_ENDPOINT_USER}User/me`
  );
  const [isFollowing, setIsFollowing] = useState<boolean>(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [shareDialogOpen, setShareDialogOpen] = useState(false);
  const [postToShare, setPostToShare] = useState<UserPost | undefined>();
  const {
    userPosts,
    loading: loadingPost,
    hasMore,
    error: errorPosts,
  } = useFetchUserPosts(user?.id ?? '', pageNumber);

  const followUnfollowUser = () => {
    initUserApi()
      .post(`/User/${user?.id}/follow/`)
      .then(() => {
        setIsFollowing(!isFollowing);
        mutate(`${process.env.REACT_APP_API_ENDPOINT_USER}User/me`);
        mutate(
          `${process.env.REACT_APP_API_ENDPOINT_USER}User/${displayName}/${tag}`
        );
      });
  };

  const onPostLike = (id: string) => {
    initUserApi()
      .post(`/Post/${id}/like`, { postId: id })
      .then(() => {
        mutate(`${process.env.REACT_APP_API_ENDPOINT_USER}Post/${id}`);
        mutate(`${process.env.REACT_APP_API_ENDPOINT_USER}User/me`);
      });
  };

  useEffect(() => {
    if (me?.id === user?.id && me?.id !== undefined && user?.id !== undefined) {
      history.push('/profile');
    }

    setIsFollowing(me?.followedUsers.includes(user?.id ?? '') ?? false);
  }, [me, user]);

  useEffect(() => {
    setPageNumber(1);
  }, [displayName, tag]);

  /*
    next 20ish lines of code creates the infinitForumApiy scroll feeling.
  */
  const observer = useRef<IntersectionObserver>();
  const lastPostElementRef = useCallback(
    (node) => {
      if (!user) return;
      if (observer.current) {
        observer.current;
      }

      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setPageNumber((prevPageNumber) => prevPageNumber + 1);
        }
      });

      if (node) {
        observer.current.observe(node);
      }
    },
    [user, hasMore]
  );

  if (error) {
    return (
      <p>
        The user "{displayName}" with tag "{tag}" was not found
      </p>
    );
  }

  if (!user) return <div>{!user && <LoadingSpinner />}</div>;

  return (
    <div className="w-full flex flex-col gap-3 items-center justify-center">
      <div className="w-full flex flex-col gap-4 items-center justify-center">
        <div className="rounded-full h-48 w-48 md:h-64 md:w-64  flex items-center justify-center relative">
          <img
            src={user?.imageUrl}
            alt=""
            className="rounded-3xl h-48 w-48 md:h-64 md:w-64  border-secondary-default border-4 shadow-xl object-cover"
          />
        </div>
      </div>

      {/* user follow info */}
      <div className="w-full md:w-4/5 xl:w-3/5 flex flex-col gap-2">
        <Card className="flex flex-col w-full gap-2 relative">
          <p className="text-lg font-bold">{`${user?.firstName} ${user?.lastName}`}</p>
          <p className="text-xs text-secondary-default text-opacity-80">{`@${user?.uniqueIdentifier.displayName}#${user?.uniqueIdentifier.tag}`}</p>
          <p className="text-md">{user?.about}</p>
          <div className="flex gap-1">
            <GoCalendar />
            <p className="text-xs text-secondary-default text-opacity-80">
              Member since {formatJoinedAt(user?.createdAt ?? 0)}
            </p>
          </div>
          {!isFollowing ? (
            <div
              className="flex flex-col gap-2 justify-center items-center absolute top-1 right-1 cursor-pointer hover:bg-primary-default hover:bg-opacity-50 p-2 rounded-md"
              onClick={followUnfollowUser}
            >
              <RiUserFollowLine className="text-base" />
              <p className="text-xs hidden md:flex">follow</p>
            </div>
          ) : (
            <div
              className="flex flex-col gap-2 justify-center items-center absolute top-1 right-1 cursor-pointer hover:bg-primary-default hover:bg-opacity-50 p-2 rounded-md"
              onClick={followUnfollowUser}
            >
              <RiUserUnfollowFill className="text-base" />
              <p className="text-xs hidden md:flex">unfollow</p>
            </div>
          )}
        </Card>

        <Card className="flex text-xs gap-2 w-full">
          <Link
            to={{
              pathname: `/following/${user?.uniqueIdentifier.displayName}/${user?.uniqueIdentifier.tag}`,
              state: { id: user?.id },
            }}
          >
            <div className="flex gap-1 hover:bg-opacity-50 hover:bg-primary-default p-2 rounded-lg">
              <p>{user?.numOfFollowing} </p>
              <p className="text-xs text-light-default text-opacity-50">
                Following,
              </p>
            </div>
          </Link>
          <Link
            to={{
              pathname: `/followers/${user?.uniqueIdentifier.displayName}/${user?.uniqueIdentifier.tag}`,
              state: { id: user?.id },
            }}
          >
            <div className="flex gap-1 hover:bg-opacity-50 hover:bg-primary-default p-2 rounded-lg">
              <p>{user?.numOfFollowers} </p>
              <p className="text-xs text-light-default text-opacity-50">
                Followers,
              </p>
            </div>
          </Link>
          <div className="flex gap-1 justify-center items-center">
            <FaGlobeAmericas />
            <p className="text-light-default text-opacity-50">
              {user?.country}
            </p>
          </div>
        </Card>
      </div>

      <div className="flex flex-col w-full md:w-4/5 xl:w-3/5 gap-8">
        {userPosts?.map((post, index) => {
          if (userPosts.length === index + 1) {
            return (
              <UserPostCard
                key={post.id}
                lastElRef={lastPostElementRef}
                post={post}
                myPost={false}
                onLikePost={onPostLike}
                isLikingPost={me?.likedUserPosts.includes(post.id) ?? false}
                onClickShare={() => {
                  setPostToShare(post);
                  setShareDialogOpen(true);
                }}
              />
            );
          } else {
            return (
              <UserPostCard
                post={post}
                key={post.id}
                myPost={false}
                onLikePost={onPostLike}
                isLikingPost={me?.likedUserPosts.includes(post.id) ?? false}
                onClickShare={() => {
                  setPostToShare(post);
                  setShareDialogOpen(true);
                }}
              />
            );
          }
        })}
        <ShareDialog
          open={shareDialogOpen}
          content={
            'Wow look at this cool post i found on connectsocial.online\n\n'
          }
          shareUrl={`${window.location.href}/${postToShare?.id ?? ''}`}
          handleClose={() => setShareDialogOpen(false)}
        />
        <div className="flex w-full justify-center items-center">
          {loadingPost && <LoadingSpinner />}
        </div>
      </div>
    </div>
  );
};
