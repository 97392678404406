import React, { useEffect } from 'react';
import { useHistory } from 'react-router';

import { ConLink } from '../components/feature/home/ConLink/ConLink';
import { ConButton } from '../components/feature/shared/ConButton/ConButton';
import { ReactComponent as Boi } from './../ilustrations/boyGaming.svg';

export default function LandingPage() {
  const history = useHistory();

  useEffect(() => {
    if (localStorage.getItem('con_token')) {
      history.push('/');
    }
  }, []);

  return (
    <div className="flex flex-col h-screen w-full items-center relative">
      <p className="w-5/6 text-center py-7 text-2xl">
        Join today and meet new people to play with!
      </p>
      <div className="flex flex-col gap-6 py-3">
        <ConLink variant="Link" to="/register">
          <ConButton text="Create Account" />
        </ConLink>
        <ConLink variant="Link" to="/register">
          <ConButton outlined={true} text="About" />
        </ConLink>
        <div className="flex">
          <p>Already have an account?</p>
          <ConLink variant="Link" to="/login">
            <p className="font-bold ml-1">Sign in</p>
          </ConLink>
        </div>
      </div>
      <div className="flex flex-col py-10">
        <h1 className="text-7xl">Connect</h1>
        <h3 className="text-sm text-right">- For Gamers, by Gamers</h3>
      </div>
      <Boi className="opacity-80 mt-5 w-11/12 h-auto md:w-1/4" />
    </div>
  );
}
