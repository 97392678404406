import React, { FC } from 'react';
import { Link } from 'react-router-dom';

import { Me } from '../../../models/UserModel';

type Props = {
  me: Me | null;
};

export const ProfileCard: FC<Props> = ({ me }) => {
  return (
    <div className="w-full max-h-3/4 bg-primary-light bg-opacity-30 rounded-xl p-4 flex flex-col gap-4">
      <div className="flex gap-4 max-h-1/2">
        <img
          src={me?.imageUrl}
          className="h-20 w-20 rounded-full object-cover"
        />
        <div className="flex flex-col gap-2">
          <p className="text-sm">{`${me?.firstName} ${me?.lastName}`}</p>
          <p className="text-xs break-all">{`@${me?.uniqueIdentifier.displayName}#${me?.uniqueIdentifier.tag}`}</p>
        </div>
      </div>
      <div className="flex gap-6 ">
        <Link
          to={{
            pathname: `/followers/${me?.uniqueIdentifier.displayName}/${me?.uniqueIdentifier.tag}`,
            state: { id: me?.id },
          }}
        >
          <p className="text-sm flex items-end gap-2 font-bold text-secondary-default rounded-md px-3 py-1 hover:bg-primary-light hover:bg-opacity-40 cursor-pointer">
            {`${me?.numOfFollowers}`}
            <p className="font-normal text-xs text-light-default text-opacity-50">
              followers
            </p>
          </p>
        </Link>
        <Link
          to={{
            pathname: `/following/${me?.uniqueIdentifier.displayName}/${me?.uniqueIdentifier.tag}`,
            state: { id: me?.id },
          }}
        >
          <p className="text-sm flex items-end gap-2 font-bold text-secondary-default rounded-md px-3 py-1 hover:bg-primary-light hover:bg-opacity-40 cursor-pointer">
            {`${me?.numOfFollowing}`}
            <p className="font-normal text-xs text-light-default text-opacity-50">
              following
            </p>
          </p>
        </Link>
      </div>
      <div>
        <p className="text-light-default text-opacity-50 text-sm">
          {me?.about}
        </p>
      </div>
    </div>
  );
};
