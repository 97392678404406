import { debounce } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import React, { FC, useEffect, useState } from 'react';
import { AiOutlineClose } from 'react-icons/ai';
import { Link } from 'react-router-dom';

import { useForumPostSearch } from '../../../hooks/useForumPostSearch';
import { useUserSearch } from '../../../hooks/useUserSearch';
import { Checkbox } from '../../../models/Checkbox';
import { ConCheckbox } from './ConCheckbox/ConCheckbox';
import LoadingSpinner from './LoadingSpinner/LoadingSpinner';

type Props = {
  open: boolean;
  handleClose: () => void;
};

export const ConSearchDialog: FC<Props> = ({ open, handleClose }) => {
  const [searchValue, setSearchValue] = useState('');
  const [forumCheckBox, setForumCheckBox] = useState<Checkbox>({
    checked: true,
    text: 'forums',
    id: 1,
  });
  const [postCheckBox, setPostCheckBox] = useState<Checkbox>({
    checked: false,
    text: 'posts',
    id: 2,
  });
  const [userCheckBox, setUserCheckBox] = useState<Checkbox>({
    checked: false,
    text: 'users',
    id: 3,
  });

  const { posts, forums, error, loading } = useForumPostSearch(
    searchValue,
    1,
    5,
    forumCheckBox.checked,
    postCheckBox.checked
  );

  const { users, error: usersErr, loading: userLoading } = useUserSearch(
    searchValue,
    1,
    5,
    userCheckBox.checked
  );

  useEffect(() => {
    setSearchValue('');
  }, []);

  const handleChange = debounce((value) => {
    setSearchValue(value);
  }, 1000);

  const toggleForumCheckbox = () => {
    setForumCheckBox((prevState) => ({
      ...prevState,
      checked: !prevState.checked,
    }));
  };

  const togglePostCheckbox = () => {
    setPostCheckBox((prevState) => ({
      ...prevState,
      checked: !prevState.checked,
    }));
  };

  const toggleUserCheckbox = () => {
    setUserCheckBox((prevState) => ({
      ...prevState,
      checked: !prevState.checked,
    }));
  };

  const clearSearchValue = () => {
    setSearchValue('');
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth
        PaperProps={{
          style: {
            backgroundColor: 'transparent',
          },
        }}
      >
        <div className="w-full bg-primary-light p-4 rounded-xl flex-col flex gap-2">
          <div className="flex justify-between items-center">
            <h2 className="text-xl">Search</h2>
            <AiOutlineClose className="text-xl" onClick={handleClose} />
          </div>
          <div className="w-full rounded-xl flex">
            <input
              placeholder="Search... 🔍"
              className="w-full bg-primary-default h-10 px-2 text-sm rounded-l-xl focus:outline-none"
              type="text"
              onChange={(e) => e.target.value && handleChange(e.target.value)}
            />
            <button
              className="bg-primary-default h-10 w-2/12 flex justify-center items-center focus:outline-none rounded-r-xl"
              onClick={clearSearchValue}
            >
              <AiOutlineClose className="text-xl" />
            </button>
          </div>

          {/* could refactors, but ok when only three */}
          <div className="flex gap-2 pl-2">
            <ConCheckbox
              box={forumCheckBox}
              onToggleCheckBox={toggleForumCheckbox}
            />
            <ConCheckbox
              box={postCheckBox}
              onToggleCheckBox={togglePostCheckbox}
            />
            <ConCheckbox
              box={userCheckBox}
              onToggleCheckBox={toggleUserCheckbox}
            />
          </div>

          {loading && searchValue && userLoading && (
            <div className="w-full flex justify-center items-center">
              <LoadingSpinner />
            </div>
          )}
          {(forums || posts || users) && (
            <div className="flex flex-col justify-evenly gap-4">
              {posts && (
                <div className="flex flex-col gap-2">
                  <h3 className="text-lg border-b w-auto border-opacity-50 border-secondary-default">
                    posts
                  </h3>
                  {posts.length < 1 && <p>No posts found</p>}
                  {posts?.map((post) => {
                    return (
                      <Link
                        to={`/forum/post/${post.id}`}
                        onClick={handleClose}
                        key={post.id}
                      >
                        <div className="flex gap-2 items-center bg-primary-light hover:bg-opacity-50 hover:bg-primary-default p-2 rounded-lg">
                          <img
                            src={post.forumIcon}
                            className="w-8 h-8 rounded-full object-cover"
                          />
                          <div className="flex flex-col">
                            <h3 className="text-sm">{post.title}</h3>
                            <p className="text-xs text-opacity-50 text-secondary-default">
                              {`${post.likes} likes - ${post.numOfComments} comments `}
                            </p>
                          </div>
                        </div>
                      </Link>
                    );
                  })}
                </div>
              )}
              {forums && (
                <div className="flex flex-col gap-2 ">
                  <h3 className="text-lg border-b w-auto border-opacity-50 border-secondary-default">
                    forums
                  </h3>
                  {forums.length < 1 && <p>No forums found</p>}
                  {forums?.map((forum) => {
                    return (
                      <Link
                        to={`/forum/${forum?.id}`}
                        onClick={handleClose}
                        key={forum.id}
                      >
                        <div className="flex gap-2 items-center bg-primary-light hover:bg-opacity-50 hover:bg-primary-default p-2 rounded-lg">
                          <img
                            src={forum.iconUrl}
                            className="w-8 h-8 rounded-full object-cover"
                          />
                          <div className="flex flex-col">
                            <h3 className="text-sm">{forum.name}</h3>
                            <p className="text-xs text-opacity-50 text-secondary-default">
                              {forum.numOfSubscribers} followers
                            </p>
                          </div>
                        </div>
                      </Link>
                    );
                  })}
                </div>
              )}
              {users && (
                <div className="flex flex-col gap-2 ">
                  <h3 className="text-lg border-b w-auto border-opacity-50 border-secondary-default">
                    users
                  </h3>
                  {users.length < 1 && <p>No users found</p>}
                  {users?.map((user) => {
                    return (
                      <Link
                        to={`/u/${user?.uniqueIdentifier.displayName}/${user?.uniqueIdentifier.tag}`}
                        onClick={handleClose}
                        key={user.id}
                      >
                        <div className="flex gap-2 items-center bg-primary-light hover:bg-opacity-50 hover:bg-primary-default p-2 rounded-lg">
                          <img
                            src={user.imageUrl}
                            className="w-8 h-8 rounded-full object-cover"
                          />
                          <div className="flex flex-col">
                            <h3 className="text-sm">{`${user.firstName} ${user.lastName}`}</h3>
                            <p className="text-xs text-opacity-50 text-secondary-default">
                              {`@${user?.uniqueIdentifier.displayName}#${user?.uniqueIdentifier.tag}`}
                            </p>
                          </div>
                        </div>
                      </Link>
                    );
                  })}
                </div>
              )}
            </div>
          )}
        </div>
      </Dialog>
    </div>
  );
};
