import Dialog from '@material-ui/core/Dialog';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { FC, useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';

import { UniqueIdentifier } from '../../../models/UserModel';
import { loggedInUser } from '../../../state/selectors/UserSelectors';
import { UIDToString } from '../../../utils/helpers/UniqueIdentifier.helper';
import { initUserApi } from '../../../utils/userUtils';

type Props = {
  open: boolean;
  handleNewChat: (receiver?: UniqueIdentifier) => void;
  handleInvalidInput: () => void;
  handleClose: () => void;
};

type MutualFollower = {
  id: string;
  uniqueIdentifier: UniqueIdentifier;
  imageUrl: string;
};

export const NewChatDialog: FC<Props> = ({
  open,
  handleNewChat,
  handleInvalidInput,
  handleClose,
}) => {
  const [receiver, setReceiver] = useState<UniqueIdentifier>();
  const [mutualFollowers, setMutualFollowers] = useState<MutualFollower[]>([]);
  const user = useRecoilValue(loggedInUser);

  // Sets the list of mutual followers when chat window is loaded
  useEffect(() => {
    initUserApi()
      .get<MutualFollower[]>('user/mutualFollowers')
      .then((res) => {
        if (user == undefined) return;
        // Adds the logged in user to the list so they can chat with themselves
        setMutualFollowers([
          ...res.data,
          {
            id: user?.id,
            uniqueIdentifier: user?.uniqueIdentifier,
            imageUrl: user?.imageUrl,
          },
        ]);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  // An undefined receiver means that the parsing of the input as UID failed
  // If receiver is an ineligible user, it is handled in the parent component
  const handleSendPress = () => {
    if (receiver == undefined) handleInvalidInput();
    else handleNewChat(receiver);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth
      PaperProps={{
        style: {
          backgroundColor: 'transparent',
        },
      }}
    >
      <div className="w-full bg-primary-default p-4 rounded-xl flex-col flex gap-2">
        <p>Open a chat with...</p>
        <Autocomplete
          id="receiverUID"
          fullWidth
          options={mutualFollowers}
          getOptionLabel={(option: MutualFollower) => {
            return UIDToString(option.uniqueIdentifier);
          }}
          color="primary"
          noOptionsText="No mutual followers"
          onChange={(e, v) => {
            setReceiver(v?.uniqueIdentifier);
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder="JohnDoe#1234"
              variant="outlined"
              onKeyPress={(e) => {
                if (e.key == 'Enter') handleSendPress();
              }}
            />
          )}
        />
        <button onClick={() => handleSendPress()}>Open chat</button>
      </div>
    </Dialog>
  );
};
