import { useSWRInfinite } from 'swr';

import { FollowerModel } from '../models/UserModel';
import fetcher from '../utils/fetcher';

export const useFollowinfo = (userid: string, path: string) => {
  const PAGE_LIMIT = 5;

  const { data, error, size, setSize } = useSWRInfinite(
    (index) =>
      `${process.env.REACT_APP_API_ENDPOINT_USER}user/${userid}/${path}/${
        index + 1
      }`,
    fetcher
  );

  const users: FollowerModel[] = data ? [].concat(...data) : [];
  const isLoadingInitialData = !data && !error;
  const isLoadingMore =
    isLoadingInitialData ||
    (size > 0 && data && typeof data[size - 1] === 'undefined');
  const isEmpty = data?.[0]?.length === 0;
  const isReachingEnd =
    isEmpty || (data && data[data.length - 1]?.length < PAGE_LIMIT);

  return { users, error, isLoadingMore, size, setSize, isReachingEnd };
};
