// define this function somewhere
export const localStorageEffect = (key: string) => ({
  setSelf,
  onSet,
}: any) => {
  const savedValue = localStorage.getItem(key);
  if (savedValue != null) {
    setSelf(JSON.parse(savedValue));
  }
  onSet((newValue: any) => {
    localStorage.setItem(key, JSON.stringify(newValue));
  });
};
