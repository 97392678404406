import { useEffect, useState } from 'react';

import { FeedPaginated, FeedPost } from '../models/FeedModel';
import { initUserApi } from '../utils/userUtils';

export const useFeed = (pageNumber: number) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [feedPosts, setFeedPosts] = useState<FeedPost[] | null>(null);
  const [hasMore, setHasMore] = useState(false);

  useEffect(() => {
    setLoading(true);
    setError(false);

    initUserApi()
      .get<FeedPaginated>(`/User/feed/${pageNumber}`)
      .then((res) => {
        setFeedPosts((prevPosts) => {
          if (prevPosts === null) {
            return res.data.posts;
          } else {
            return [...new Set([...prevPosts, ...res.data.posts])];
          }
        });
        setHasMore(res.data.pages >= pageNumber);
        setLoading(false);
      })
      .catch((e) => {
        console.log(e);
        setError(true);
        setLoading(false);
      });
  }, [pageNumber]);

  return { loading, error, feedPosts, hasMore };
};
