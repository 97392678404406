import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router';
import { useRecoilState } from 'recoil';
import { mutate } from 'swr';

import { countries } from '../data/countries';
import { useProfile } from '../hooks/useProfile';
import { Me } from '../models/UserModel';
import { userInfoState } from '../state/atoms/atoms';
import { initAuthApi } from '../utils/authUtils';
import { initUserApi } from '../utils/userUtils';

type Inputs = {
  firstName: string;
  lastName: string;
  gender: string;
  dateOfBirth: number;
  imageUrl: string;
  country: string;
  about: string;
  displayName: string;
};

export const SetupUser = () => {
  const { register, handleSubmit, errors, setValue } = useForm<Inputs>();
  const [userInfo, setUserInfo] = useRecoilState(userInfoState);

  const { me, loading, error } = useProfile();
  const history = useHistory();

  const onSubmit = (data: Inputs) => {
    initUserApi()
      .post<Me>(`User/SetUserInfo`, {
        firstName: data.firstName,
        lastName: data.lastName,
        gender: data.gender,
        dateOfBirth: new Date(data.dateOfBirth).getTime(),
        imageUrl: data.imageUrl,
        country: data.country,
        about: data.about,
        displayName: data.displayName,
      })
      .then((res) => {
        if (res.data.isSetup) {
          localStorage.setItem('con_userIsSetup', '1');
          setUserInfo(res.data);
          initAuthApi()
            .get('/Refresh', { withCredentials: true })
            .then((res) => {
              localStorage.setItem('con_token', res.data);
              // console.log('token from refresh call: ', res.data);
              // console.log(
              //   'token in storage: ',
              //   localStorage.getItem('con_token')
              // );
            })
            .catch((e) => {
              console.log(e);
              initAuthApi().get('Logout');
            });
          mutate(`${process.env.REACT_APP_API_ENDPOINT_USER}User/me`);
          history.push('/profile');
        }
      })
      .catch((e) => console.log(e));
  };

  const convertUnixToDate = (unixTimeStamp: number) => {
    return new Date(unixTimeStamp).toISOString().substr(0, 10);
  };

  useEffect(() => {
    if (me) {
      setValue('firstName', me.firstName);
      setValue('lastName', me.lastName);
      setValue('gender', me.gender);
      setValue('dateOfBirth', convertUnixToDate(me?.dateOfBirth));
      setValue('imageUrl', me.imageUrl);
      setValue('country', me.country);
      setValue('about', me.about);
      setValue('displayName', me.uniqueIdentifier.displayName);
    }
  }, [me]);

  // const authWithSteam = () => {
  //   initUserApi()
  //     .get(`/Game/AuthorizeExternalGame`, { params: { provider: 'Steam' } })
  //     .then((res) => console.log(res));
  // };

  return (
    <div className="w-11/12 lg:w-3/5 flex flex-col gap-6 items-center">
      <h1 className="text-2xl">Update user information</h1>
      <form
        className="w-full flex flex-col gap-4 pb-4"
        onSubmit={handleSubmit(onSubmit)}
        autoComplete="new-password"
      >
        <div className="flex gap-3">
          <div className="flex flex-col gap-2">
            <label>FirstName:</label>
            <input
              name="firstName"
              type="text"
              className="w-full text-light-default bg-primary-light rounded-md p-3 text-xs outline-none border border-secondary-default border-opacity-60 focus:border-secondary-default"
              placeholder="Surname *"
              ref={register({ required: true, minLength: 2 })}
              autoComplete="new-password"
            />
            {errors.firstName && (
              <p className="text-xs pl-3 text-error-default">
                Firstname is required an have a min length of 2
              </p>
            )}
          </div>
          <div className="flex flex-col gap-2">
            <label>Lastname:</label>
            <input
              name="lastName"
              type="text"
              className="w-full text-light-default bg-primary-light rounded-md p-3 text-xs outline-none border border-secondary-default border-opacity-60 focus:border-secondary-default"
              placeholder="Lastname *"
              ref={register({ required: true, minLength: 2 })}
              autoComplete="new-password"
            />
            {errors.lastName && (
              <p className="text-xs pl-3 text-error-default">
                Lastname is required an have a min length of 2
              </p>
            )}
          </div>
        </div>

        <div className="flex gap-3">
          <div className="flex flex-col gap-2">
            <label>Display name:</label>
            <input
              name="displayName"
              type="text"
              className="w-full text-light-default bg-primary-light rounded-md p-3 text-xs outline-none border border-secondary-default border-opacity-60 focus:border-secondary-default"
              placeholder="Display name *"
              ref={register({ required: true, minLength: 3, maxLength: 24 })}
              autoComplete="new-password"
            />
            {errors.displayName && (
              <p className="text-xs pl-3 text-error-default">
                displayName is required an have a min length of 3 and less than
                24.
              </p>
            )}
          </div>
          <div className="flex flex-col gap-2 w-1/3">
            <label>Unique tag:</label>
            <input
              disabled
              type="text"
              className="w-full text-light-dark bg-primary-light bg-opacity-0 rounded-md p-3 text-base outline-none"
              value={`#${me?.uniqueIdentifier.tag}`}
            />
          </div>
        </div>

        <div className="flex flex-col gap-2">
          <label>Email:</label>
          <input
            className="w-full text-light-dark bg-primary-light bg-opacity-0 rounded-md p-3 text-xs outline-none"
            value={me?.email}
            disabled
          />
        </div>
        <div className="flex gap-3">
          <div className="flex flex-col gap-2  w-full">
            <label>Gender:</label>
            <select
              name="gender"
              ref={register({
                required: true,
              })}
              autoComplete="new-password"
              className="w-full text-light-default bg-primary-light rounded-md p-3 text-xs outline-none border border-secondary-default border-opacity-60 focus:border-secondary-default"
            >
              <option value="Male">Male</option>
              <option value="Female">Female</option>
              <option value="Other">Other</option>
            </select>
            {errors.gender && (
              <p className="text-xs pl-3 text-error-default">
                You must choose a gender
              </p>
            )}
          </div>
          <div className="flex flex-col gap-2 w-full">
            <label>Date of Birth:</label>
            <input
              name="dateOfBirth"
              type="date"
              className="w-full text-light-default bg-primary-light rounded-md p-3 text-xs outline-none border border-secondary-default border-opacity-60 focus:border-secondary-default"
              placeholder="dateOfBirth *"
              ref={register({
                required: true,
              })}
            />
            {errors.dateOfBirth && (
              <p className="text-xs pl-3 text-error-default">
                You must set a date of birth
              </p>
            )}
          </div>
        </div>
        <div className="flex flex-col items-center gap-3">
          <label className="self-start">Profile picture (URL):</label>
          <input
            name="imageUrl"
            type="text"
            className="w-full text-light-default bg-primary-light rounded-md p-3 text-xs outline-none border border-secondary-default border-opacity-60 focus:border-secondary-default"
            placeholder="Profile pic (url) *"
            ref={register({
              required: true,
            })}
            autoComplete="new-password"
          />
          {errors.imageUrl && (
            <p className="text-xs pl-3 text-error-default">
              Please supply a imageUrl
            </p>
          )}
          <img
            src={me?.imageUrl}
            className="rounded-full w-56 h-56 object-cover"
          />
          <p>Current profile pic</p>
        </div>
        <div className="flex flex-col gap-2 w-full">
          <label>Country:</label>
          <input
            name="country"
            type="text"
            className="w-full text-light-default bg-primary-light rounded-md p-3 text-xs outline-none border border-secondary-default border-opacity-60 focus:border-secondary-default"
            placeholder="Country *"
            ref={register({
              required: true,
            })}
            list="countries"
            autoComplete="new-password"
          />
          <datalist id="countries">
            {countries.map((country) => {
              return <option value={country.name} key={country.code} />;
            })}
          </datalist>
          {errors.country && (
            <p className="text-xs pl-3 text-error-default">
              You must pick a country
            </p>
          )}
        </div>
        <div className="flex flex-col gap-2 w-full">
          <label>About:</label>
          <textarea
            name="about"
            className="w-full text-light-default h-40 bg-primary-light rounded-md p-3 text-xs outline-none border border-secondary-default border-opacity-60 focus:border-secondary-default"
            placeholder="About"
            ref={register}
            autoComplete="new-password"
          />
        </div>
        {/* <div>
          <a
            href={`${process.env.REACT_APP_API_ENDPOINT_USER}Game/Steam/${me?.id}`}
          >
            <p>Add Steam account</p>
          </a>
        </div> */}
        <input
          value="Save changes"
          type="submit"
          className="border border-secondary-default rounded-lg bg-secondary-default text-primary-default"
        />
      </form>
    </div>
  );
};
