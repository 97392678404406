import { HubConnection } from '@microsoft/signalr';
import { atom } from 'recoil';

import { Me } from '../../models/UserModel';
import { localStorageEffect } from '../../utils/helpers/recoilState.helper';

/*
An atom represents a piece of state. Atoms can be read from 
and written to from any component. 
Components that read the value of an atom are implicitly 
subscribed to that atom, so any atom updates will result 
in a re-render of all components subscribed to that atom:
*/
export const isLoggedInState = atom({
  key: 'isLoggedInState', // unique ID (with respect to other atoms/selectors)
  default: localStorage.getItem('con_token') === null ? false : true, // default value (aka initial value)
  effects_UNSTABLE: [localStorageEffect('isLoggedInState')],
});

export const sideBarShownState = atom({
  key: 'sideBarShownState', // unique ID (with respect to other atoms/selectors)
  default: false, // default value (aka initial value)
});

export const clientToken = atom({
  key: 'clientToken', // unique ID (with respect to other atoms/selectors)
  default: '', // default value (aka initial value)
});

export const profileNavMenuState = atom({
  key: 'profileNavMenu', // unique ID (with respect to other atoms/selectors)
  default: false, // default value (aka initial value)
});

export const userInfoState = atom<Me | null>({
  key: 'userInfo', // unique ID (with respect to other atoms/selectors)
  default: null, // default value
  effects_UNSTABLE: [localStorageEffect('userInfo')],
});

export const chatState = atom<HubConnection | null>({
  key: 'chatState',
  default: null,
});
