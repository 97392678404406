import './UserPostCard.scss';

import React, { FC } from 'react';
import { AiFillHeart, AiOutlineHeart } from 'react-icons/ai';
import { BiShare } from 'react-icons/bi';
import { BsTrash } from 'react-icons/bs';
import { VscComment } from 'react-icons/vsc';
import { Link } from 'react-router-dom';

import { UserPost } from '../../../../models/UserPostModel';
import { formatDate } from '../../../../utils/utils';

type Props = {
  post: UserPost;
  lastElRef?: any;
  handleDeletePost?: (id: string) => void;
  myPost: boolean;
  onLikePost: (id: string) => void;
  isLikingPost: boolean;
  onClickShare: () => void;
};

export const UserPostCard: FC<Props> = ({
  post,
  lastElRef,
  handleDeletePost,
  myPost,
  onLikePost,
  isLikingPost,
  onClickShare,
}) => {
  return (
    <div
      className="w-full flex flex-col h-full bg-primary-light p-3 rounded-md gap-2 border-2 border-secondary-dark border-opacity-50 relative"
      ref={lastElRef}
    >
      {myPost && (
        <div className="cursor-pointer hover:bg-primary-default p-2 rounded-md absolute right-3 top-3">
          <BsTrash
            className=""
            onClick={() => handleDeletePost && handleDeletePost(post.id)}
          />
        </div>
      )}
      <Link to={`/u/${post.author.displayName}/${post.author.tag}`}>
        <div className="flex items-center w-full justify-between">
          <div className="flex cursor-pointer hover:bg-primary-default p-2 rounded-md hover:bg-opacity-40">
            <img
              src={post.authorIconUrl}
              alt=""
              className="w-10 h-10 rounded-full object-cover"
            />
            <div className="flex flex-col pl-3">
              <p className="text-sm">{`${post.authorFirstName} ${post.authorLastName}`}</p>
              <p className="text-xs text-opacity-70 text-secondary-default">{`@${
                post.author.displayName
              }#${post.author.tag} - ${formatDate(post.createdAt)}`}</p>
            </div>
          </div>
        </div>
      </Link>
      <Link
        to={`/u/${post.author.displayName}/${post.author.tag}/${post.id}`}
        className="flex flex-col gap-2"
      >
        <div className="">
          <p className="text-xs text-light-default break-words">
            {post.content}
          </p>
        </div>
        <div className="images-container">
          {post.imageUrls.map((image, index) => {
            return (
              image && (
                <img
                  src={image}
                  alt=""
                  className="rounded-md w-full"
                  key={index}
                />
              )
            );
          })}
        </div>
      </Link>

      <div className="w-full flex flex-row justify-between px-5 border-t border-secondary-default border-opacity-40 pt-2">
        <div
          className="flex justify-center items-center gap-2 cursor-pointer hover:bg-primary-default p-2 rounded-md"
          onClick={() => onLikePost(post.id)}
        >
          {isLikingPost ? (
            <AiFillHeart className="text-lg" />
          ) : (
            <AiOutlineHeart className="text-lg" />
          )}
        </div>
        <Link
          to={`/u/${post.author.displayName}/${post.author.tag}/${post.id}`}
        >
          <div className="flex gap-2 items-center cursor-pointer hover:bg-primary-default p-2 rounded-md">
            <p className="text-xs">{post.numOfComments}</p>
            <VscComment className="text-lg" />
          </div>
        </Link>
        <div
          className="flex gap-2 items-center cursor-pointer hover:bg-primary-default p-2 rounded-md"
          onClick={onClickShare}
        >
          <BiShare className="text-lg" />
        </div>
      </div>
    </div>
  );
};
