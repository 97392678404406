import React, { useState } from 'react';
import { useEffect } from 'react';
import { AiFillHeart, AiOutlineHeart } from 'react-icons/ai';
import { BiShare } from 'react-icons/bi';
import { BsFillReplyFill } from 'react-icons/bs';
import { VscComment } from 'react-icons/vsc';
import { Link, useParams } from 'react-router-dom';
import useSWR, { mutate } from 'swr';

import { useConSnackbar } from '../../../../hooks/useConSnackbar';
import { Me } from '../../../../models/UserModel';
import { UserPost } from '../../../../models/UserPostModel';
import { initUserApi } from '../../../../utils/userUtils';
import { formatDate } from '../../../../utils/utils';
import LoadingSpinner from '../../shared/LoadingSpinner/LoadingSpinner';
import { ShareDialog } from '../../sharing/ShareDialog';
import { UserComment } from '../UserComment/UserComment';
import { UserCommentDialog } from '../UserCommentDialog/UserCommentDialog';

type ParamTypes = {
  id: string;
};

export const UserSpecificPost = () => {
  const { id } = useParams<ParamTypes>();
  const { data, error } = useSWR<UserPost>(
    `${process.env.REACT_APP_API_ENDPOINT_USER}Post/${id}`
  );
  const { data: me, error: meError } = useSWR<Me>(
    `${process.env.REACT_APP_API_ENDPOINT_USER}User/me`
  );
  const [isLikingPost, setIsLikingPost] = useState(false);
  const [commentDialogOpen, setCommentDialogOpen] = useState(false);
  const [shareDialogOpen, setShareDialogOpen] = useState(false);
  const [enque] = useConSnackbar();

  const onPostComment = (newComment: string) => {
    initUserApi()
      .post(`/Post/${id}`, { content: newComment })
      .then(() => {
        mutate(`${process.env.REACT_APP_API_ENDPOINT_USER}Post/${id}`);
        handleDialogClose();
        enque({
          message: 'Your comment was added!',
          variant: 'success',
        });
      });
  };

  const onPostLike = () => {
    initUserApi()
      .post(`/Post/${id}/like`, { postId: id })
      .then(() => {
        mutate(`${process.env.REACT_APP_API_ENDPOINT_USER}Post/${id}`);
        mutate(`${process.env.REACT_APP_API_ENDPOINT_USER}User/me`);
      });
  };

  useEffect(() => {
    setIsLikingPost(me?.likedUserPosts.includes(id) ?? false);
  }, [me]);

  const handleDialogClose = () => {
    setCommentDialogOpen(false);
  };

  return (
    <div className="w-full lg:mb-5 lg:px-5 flex flex-col items-center justify-center gap-2 p-3 border-l border-r border-primary-light">
      <div className="flex w-full gap-3">
        <div className="min-w-1/6 max-w-1/6 flex flex-col items-center gap-2">
          <Link to={`/u/${data?.author.displayName}/${data?.author.tag}`}>
            <div>
              <img
                src={data?.authorIconUrl}
                alt=""
                className="w-12 h-12 rounded-full object-cover cursor-pointer"
              />
            </div>
          </Link>
          <div className="w-px border-l-2 border-primary-light h-full"></div>
        </div>

        <div className="flex flex-col w-5/6 gap-2 ">
          <div className="flex gap-2">
            <Link
              to={`/u/${data?.author.displayName}/${data?.author.tag}`}
              className="cursor-pointer hover:bg-primary-light p-2 rounded-md"
            >
              <p className="text-sm ">{`${data?.authorFirstName} ${data?.authorLastName}`}</p>
              <p className="text-xs text-opacity-70 text-secondary-default">{`@${
                data?.author.displayName
              }#${data?.author.tag} - ${formatDate(data?.createdAt)}`}</p>
            </Link>
          </div>
          <div className="w-full">
            <p className="text-light-default text-sm">{data?.content}</p>
            <div className="flex flex-col gap-3 w-full">
              <div className="images-container">
                {data?.imageUrls.map((image, index) => {
                  return (
                    image && (
                      <img
                        src={image}
                        alt=""
                        className="w-full lg:rounded-2xl"
                        key={index}
                      />
                    )
                  );
                })}
              </div>
              <div className="flex pt-3 gap-16">
                <div
                  className="flex items-center cursor-pointer hover:bg-primary-light p-2 rounded-md"
                  onClick={onPostLike}
                >
                  {isLikingPost ? (
                    <AiFillHeart className="text-lg mr-2" />
                  ) : (
                    <AiOutlineHeart className="text-lg mr-2" />
                  )}
                  <p className="text-sm">{data?.likes}</p>
                </div>
                <div
                  className="flex items-center cursor-pointer hover:bg-primary-light p-2 rounded-md"
                  onClick={() => setCommentDialogOpen(true)}
                >
                  <VscComment className="text-lg mr-2" />
                  <p className="text-sm">{data?.comments.length}</p>
                </div>
                <div
                  className="flex items-center gap-2 cursor-pointer hover:bg-primary-light p-2 rounded-md"
                  onClick={() => setCommentDialogOpen(true)}
                >
                  <p className="text-xs">reply</p>
                  <BsFillReplyFill />
                </div>
                <div
                  className="flex items-center cursor-pointer hover:bg-primary-light p-2 rounded-md"
                  onClick={() => setShareDialogOpen(true)}
                >
                  <BiShare className="text-lg mr-2" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <UserCommentDialog
        open={commentDialogOpen}
        handleAddComment={onPostComment}
        handleClose={handleDialogClose}
        me={me ?? null}
      />
      <ShareDialog
        open={shareDialogOpen}
        content={
          'Wow look at this cool post i found on connectsocial.online\n\n'
        }
        shareUrl={window.location.href}
        handleClose={() => setShareDialogOpen(false)}
      />
      <div>{!data && <LoadingSpinner />}</div>
      <div className="flex flex-col w-full gap-4">
        {data?.comments.map((comment) => {
          return (
            <UserComment
              comment={comment}
              isLikingComment={
                me?.likedUserComments.includes(comment.id) ?? false
              }
              postId={data.id}
              key={comment.id}
            />
          ); //todo
        })}
      </div>
    </div>
  );
};
