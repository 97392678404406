import React, { FC } from 'react';
import { AiFillHeart, AiOutlineHeart } from 'react-icons/ai';
import { Link } from 'react-router-dom';
import { mutate } from 'swr';

import { UserPost } from '../../../../models/UserPostModel';
import { initUserApi } from '../../../../utils/userUtils';
import { formatDate } from '../../../../utils/utils';

type Props = {
  comment: UserPost;
  isLikingComment: boolean;
  postId: string;
};

export const UserComment: FC<Props> = ({
  comment,
  isLikingComment,
  postId,
}) => {
  const handleLike = () => {
    initUserApi()
      .post(`/post/${comment.id}/like`)
      .then(() => {
        mutate(`${process.env.REACT_APP_API_ENDPOINT_USER}User/me`);
        mutate(`${process.env.REACT_APP_API_ENDPOINT_USER}Post/${postId}`);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  return (
    <div className="w-full flex justify-start gap-3">
      <div className="min-w-1/6 max-w-1/6 flex flex-col items-center gap-2">
        <div>
          <Link
            to={`/u/${comment.author.displayName}/${comment.author.tag}`}
            className="cursor-pointer"
          >
            <img
              src={comment?.authorIconUrl}
              alt=""
              className="w-12 h-12 rounded-full object-cover"
            />
          </Link>
        </div>
        <div className="w-px border-l-2 border-primary-light h-full"></div>
      </div>
      <div className="flex flex-col gap-2 w-5/6">
        <div className="flex gap-2">
          <Link
            to={`/u/${comment.author.displayName}/${comment.author.tag}`}
            className="cursor-pointer hover:bg-primary-light p-2 rounded-md"
          >
            <p className="text-xs font-bold">{`${comment?.authorFirstName}`}</p>
            <p className="text-xs text-opacity-70 text-secondary-default">{`@${
              comment?.author.displayName
            }#${comment?.author.tag} - ${formatDate(comment?.createdAt)}`}</p>
          </Link>
        </div>
        <p className="text-xs text-light-default w-11/12 break-words">
          {comment.content}
        </p>
        <div className="flex justify-start">
          <div
            className="flex items-center cursor-pointer hover:bg-primary-light p-2 rounded-md"
            onClick={handleLike}
          >
            {isLikingComment ? (
              <AiFillHeart className="text-lg mr-2" />
            ) : (
              <AiOutlineHeart className="text-lg mr-2" />
            )}
            <p className="text-xs">{comment.likes}</p>
          </div>
        </div>
      </div>
    </div>
  );
};
