import axios from 'axios';
import jwt_decode from 'jwt-decode';

import { DecodedTokenType, initAuthApi } from './authUtils';

export const initForumApi = () => {
  const token = localStorage.getItem('con_token');
  // const [isLoggedIn, setIsLoggedIn] = useRecoilState(isLoggedInState);

  const headers: HeadersInit = {
    // ifi
    Accept: 'application/json',
  };

  if (token) {
    const decoded: DecodedTokenType = jwt_decode(token);

    if (decoded.exp < new Date().getTime() / 1000) {
      initAuthApi()
        .get('/Refresh', { withCredentials: true })
        .then((res) => {
          localStorage.setItem('con_token', res.data);
        })
        .catch((e) => {
          console.log(e);
          initAuthApi().get('Logout');
        });
    }
  }

  if (token) {
    headers.Authorization = `Bearer ${token}`;
  }

  const client = axios.create({
    baseURL: process.env.REACT_APP_API_ENDPOINT_FORUM,
    timeout: 31000,
    headers: headers,
    withCredentials: true,
  });
  return client;
};
