import React, { FC } from 'react';
import { IoIosArrowForward } from 'react-icons/io';
import { Link } from 'react-router-dom';

import { FollowerModel } from '../../../models/UserModel';

type Props = {
  user: FollowerModel;
};

export const FollowerUserCard: FC<Props> = ({ user }) => {
  return (
    <Link
      to={`/u/${user.uniqueIdentifier.displayName}/${user.uniqueIdentifier.tag}`}
    >
      <div className="flex gap-4 items-center w-full justify-between rounded-md p-3 hover:bg-primary-light hover:bg-opacity-40 cursor-pointer">
        <div className="flex items-center gap-2">
          <img
            src={user.imageUrl}
            className="w-10 h-10 rounded-full object-cover"
          />
          <div>
            <p className="text-sm">{`${user.firstName} ${user.lastName}`}</p>
            <p className="text-xs text-light-default text-opacity-50">{`@${user.uniqueIdentifier.displayName}#${user.uniqueIdentifier.tag}`}</p>
          </div>
        </div>
        <IoIosArrowForward className="w-5 h-5 justify-end" />
      </div>
    </Link>
  );
};
