import React, { useCallback, useEffect, useRef, useState } from 'react';
import { BsGearFill } from 'react-icons/bs';
import { FaGlobeAmericas } from 'react-icons/fa';
import { GoCalendar } from 'react-icons/go';
import { Link } from 'react-router-dom';
import useSWR, { mutate } from 'swr';

import { Card } from '../components/feature/shared/Card';
import LoadingSpinner from '../components/feature/shared/LoadingSpinner/LoadingSpinner';
import { ShareDialog } from '../components/feature/sharing/ShareDialog';
import { ConCreateUserPostDialog } from '../components/feature/user/ConCreateUserPostDialog';
import { UserPostCard } from '../components/feature/user/UserPostCard/UserPostCard';
import { useConSnackbar } from '../hooks/useConSnackbar';
import { useFetchUserPosts } from '../hooks/useFetchUserPosts';
import { Me } from '../models/UserModel';
import { UserPost } from '../models/UserPostModel';
import { initUserApi } from '../utils/userUtils';
import { formatJoinedAt } from '../utils/utils';

export default function Profile() {
  const { data: me, error: meError } = useSWR<Me>(
    `${process.env.REACT_APP_API_ENDPOINT_USER}User/me`
  );
  const [pageNumber, setPageNumber] = useState(1);
  const {
    userPosts,
    loading: loadingPost,
    hasMore,
    error: errorPosts,
  } = useFetchUserPosts(me?.id ?? '', pageNumber);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [myPosts, setMyPosts] = useState<UserPost[]>([]);
  const [shareDialogOpen, setShareDialogOpen] = useState(false);
  const [postToShare, setPostToShare] = useState<UserPost | undefined>();
  const [enque] = useConSnackbar();

  useEffect(() => {
    setMyPosts(userPosts ?? []);
  }, [userPosts]);

  const handleDialogOpen = () => {
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const onSharePost = (imageList: { imageUrl: string }[], content: string) => {
    const list = imageList.filter((url) => url.imageUrl != '');

    initUserApi()
      .post('/post', {
        content: content,
        imageUrls: list,
      })
      .then((res) => {
        setMyPosts((prev) => [res.data, ...prev]);
        handleDialogClose();
        enque({
          message: 'Your post was shared!',
          variant: 'success',
        });
      })
      .catch((e) => console.log(e));
  };

  const onDeletePost = (id: string) => {
    initUserApi()
      .delete(`/Post/${id}`)
      .then(() => {
        setMyPosts((prev) => prev?.filter((post) => id !== post.id) ?? []);
        enque({
          message: 'Post deleted.',
          variant: 'success',
        });
      });
  };

  const onPostLike = (id: string) => {
    initUserApi()
      .post(`/Post/${id}/like`, { postId: id })
      .then(() => {
        mutate(`${process.env.REACT_APP_API_ENDPOINT_USER}Post/${id}`);
        mutate(`${process.env.REACT_APP_API_ENDPOINT_USER}User/me`);
      });
  };

  /*
    next 20ish lines of code creates the infinitForumApiy scroll feeling.
  */
  const observer = useRef<IntersectionObserver>();
  const lastPostElementRef = useCallback(
    (node) => {
      if (loadingPost) return;
      if (observer.current) {
        observer.current;
      }

      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setPageNumber((prevPageNumber) => prevPageNumber + 1);
        }
      });

      if (node) {
        observer.current.observe(node);
      }
    },
    [loadingPost, hasMore]
  );

  return (
    <div className="w-full flex flex-col gap-3 items-center justify-center">
      <div className="w-full flex flex-col gap-4 items-center justify-center">
        <div className="rounded-full h-48 w-48 md:h-64 md:w-64 flex items-center justify-center relative">
          <img
            src={me?.imageUrl}
            alt=""
            className="rounded-3xl h-48 w-48 md:h-64 md:w-64  border-secondary-default border-4 shadow-xl object-cover"
          />
        </div>
      </div>

      {/* user follow info */}
      <div className="w-full md:w-4/5 xl:w-3/5 flex flex-col gap-2">
        <Card className="flex flex-col w-full gap-2 relative">
          <p className="text-lg font-bold">{`${me?.firstName} ${me?.lastName}`}</p>
          <p className="text-xs text-secondary-default text-opacity-80">{`@${me?.uniqueIdentifier.displayName}#${me?.uniqueIdentifier.tag}`}</p>
          <p className="text-sm">{me?.about}</p>
          <div className="flex gap-1">
            <GoCalendar />
            <p className="text-xs text-secondary-default text-opacity-80">
              Member since {formatJoinedAt(me?.createdAt ?? 0)}
            </p>
          </div>
          <Link to="/user/setup" className="">
            <div className="flex flex-col gap-2 justify-center items-center absolute top-1 right-1 cursor-pointer hover:bg-primary-default hover:bg-opacity-50 p-2 rounded-md">
              <BsGearFill className="text-base" />
              <p className="text-xs hidden md:flex">Update</p>
            </div>
          </Link>
        </Card>

        <Card className="flex text-xs gap-2 w-full">
          <Link
            to={{
              pathname: `/following/${me?.uniqueIdentifier.displayName}/${me?.uniqueIdentifier.tag}`,
              state: { id: me?.id },
            }}
          >
            <div className="flex gap-1 hover:bg-opacity-50 hover:bg-primary-default p-2 rounded-lg">
              <p>{me?.numOfFollowing} </p>
              <p className="text-xs text-light-default text-opacity-50">
                Following,
              </p>
            </div>
          </Link>
          <Link
            to={{
              pathname: `/followers/${me?.uniqueIdentifier.displayName}/${me?.uniqueIdentifier.tag}`,
              state: { id: me?.id },
            }}
          >
            <div className="flex gap-1 hover:bg-opacity-50 hover:bg-primary-default p-2 rounded-lg">
              <p>{me?.numOfFollowers} </p>
              <p className="text-xs text-light-default text-opacity-50">
                Followers,
              </p>
            </div>
          </Link>
          <div className="flex gap-1 justify-center items-center">
            <FaGlobeAmericas />
            <p className="text-light-default text-opacity-50">{me?.country}</p>
          </div>
        </Card>
      </div>

      <div className="w-full flex flex-col bg-primary-light rounded-lg p-2 gap-3 md:w-4/5 xl:w-3/5">
        <div className="flex gap-2 justify-start items-center">
          <img
            src={me?.imageUrl}
            alt=""
            className="w-10 h-10 rounded-full object-cover"
          />
          <div>
            <p className="text-sm">{`${me?.firstName} ${me?.lastName}`}</p>
            <p className="text-xs text-secondary-default text-opacity-50">{`@${me?.uniqueIdentifier.displayName}#${me?.uniqueIdentifier.tag}`}</p>
          </div>
        </div>
        <textarea
          className=" w-full bg-primary-default p-3 text-xs outline-none rounded-lg"
          placeholder="Whats Happening?"
          onClick={handleDialogOpen}
        ></textarea>
        <ConCreateUserPostDialog
          open={dialogOpen}
          handleClose={handleDialogClose}
          me={me ?? null}
          handleSharePost={onSharePost}
        />
      </div>

      <div className="flex flex-col w-full md:w-4/5 xl:w-3/5 gap-8">
        {myPosts?.map((post, index) => {
          if (myPosts.length === index + 1) {
            return (
              <UserPostCard
                key={post.id}
                lastElRef={lastPostElementRef}
                post={post}
                handleDeletePost={onDeletePost}
                myPost={true}
                onLikePost={onPostLike}
                isLikingPost={me?.likedUserPosts.includes(post.id) ?? false}
                onClickShare={() => {
                  setPostToShare(post);
                  setShareDialogOpen(true);
                }}
              />
            );
          } else {
            return (
              <UserPostCard
                post={post}
                key={post.id}
                handleDeletePost={onDeletePost}
                myPost={true}
                onLikePost={onPostLike}
                isLikingPost={me?.likedUserPosts.includes(post.id) ?? false}
                onClickShare={() => {
                  setPostToShare(post);
                  setShareDialogOpen(true);
                }}
              />
            );
          }
        })}
        <ShareDialog
          open={shareDialogOpen}
          content={
            'Wow look at this cool post i found on connectsocial.online\n\n'
          }
          // Has to code URL like this since the current url is /profile and not /displayname/tag
          shareUrl={`https://connectsocial.online/u/${
            postToShare?.author.displayName
          }/${postToShare?.author.tag}/${postToShare?.id ?? ''}`}
          handleClose={() => setShareDialogOpen(false)}
        />
        <div className="flex w-full justify-center items-center">
          {loadingPost && <LoadingSpinner />}
        </div>
      </div>
    </div>
  );
}
