import { useEffect, useState } from 'react';

import { Post, PostPaginated } from '../models/PostModel';
import { initForumApi } from '../utils/forumUtils';

export const usePopularPost = (pageNumber: number, pageSize: number) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [posts, setPosts] = useState<Post[] | null>(null);
  const [hasMore, setHasMore] = useState(false);

  useEffect(() => {
    setLoading(true);
    setError(false);
    initForumApi()
      .get<PostPaginated>(`post/popular/${pageNumber}/`, {
        params: { pageSize },
      })
      .then((res) => {
        setPosts((prevPosts) => {
          if (prevPosts === null) {
            return res.data.posts;
          } else {
            return [...new Set([...prevPosts, ...res.data.posts])];
          }
        });
        setHasMore(res.data.pages >= pageNumber);
        setLoading(false);
      })
      .catch((e) => {
        console.log(e);
        setError(true);
        setLoading(false);
      });
  }, [pageNumber, pageSize]);

  return { loading, error, posts, hasMore };
};
