import React from 'react';
import useSWR from 'swr';

import { MutualFollowersModel } from '../../../../models/UserModel';
import LoadingSpinner from '../../shared/LoadingSpinner/LoadingSpinner';
import { FollowerCard } from './FollowerCard';

export const MutualFollowers = () => {
  const { data: mutualFollowers, error: mutualFollowersError } = useSWR<
    MutualFollowersModel[]
  >(`${process.env.REACT_APP_API_ENDPOINT_USER}user/mutualfollowers`);

  if (!mutualFollowers) return <LoadingSpinner />;

  return (
    <div className="w-full max-h-3/4 sticky bg-primary-light bg-opacity-30 rounded-xl p-2 flex flex-col gap-2">
      <p className="text-xl font-bold flex items-center gap-2">
        People <p className="text-xs">(mutual followers)</p>
      </p>
      <div className="w-full flex flex-col gap-3 ">
        {mutualFollowers?.length > 0 ? (
          mutualFollowers?.map((user) => {
            return <FollowerCard user={user} key={user.id} />;
          })
        ) : (
          <p className="text-xs">You have 0 mutual followers right now</p>
        )}
      </div>
    </div>
  );
};
