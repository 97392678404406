import './index.scss';

import { createMuiTheme, MuiThemeProvider } from '@material-ui/core';
import jwt_decode from 'jwt-decode';
import { SnackbarProvider } from 'notistack';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import { RecoilRoot } from 'recoil';
import { SWRConfig } from 'swr';

import { Layout } from './components/feature/layout/Layout/Layout';
import reportWebVitals from './reportWebVitals';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import { DecodedTokenType, initAuthApi } from './utils/authUtils';
import fetcher from './utils/fetcher';

const THEME = createMuiTheme({
  typography: {
    fontFamily: `'Montserrat', sans-serif`,
    fontSize: 14,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
  },
  palette: {
    primary: {
      main: '#393e46',
    },
    secondary: {
      main: '#ffd369',
    },
    text: {
      primary: '#ffd369',
      secondary: '#D2D0C2',
    },
  },
});

ReactDOM.render(
  <React.StrictMode>
    <MuiThemeProvider theme={THEME}>
      <RecoilRoot>
        <SWRConfig
          value={{
            fetcher,
            dedupingInterval: 2000,
            onError: (err) => {
              console.error(err);
            },
            onErrorRetry: async (
              error,
              key,
              option,
              revalidate,
              { retryCount }
            ) => {
              if (retryCount && retryCount >= 2)
                if (error.status === 404) return;
              if (error.status === 401) {
                const token = localStorage.getItem('con_token');
                if (token) {
                  const decoded: DecodedTokenType = jwt_decode(token);
                  if (decoded.exp < new Date().getTime() / 1000) {
                    initAuthApi()
                      .get('/Refresh')
                      .then((res) => {
                        localStorage.setItem('con_token', res.data);
                      })
                      .catch((e) => console.log(e));
                  }
                }
              }
              setTimeout(() => {
                revalidate({ retryCount: retryCount ? retryCount + 1 : 1 });
              }, 1000);
            },
          }}
        >
          <SnackbarProvider>
            <Router>
              <Layout />
            </Router>
          </SnackbarProvider>
        </SWRConfig>
      </RecoilRoot>
    </MuiThemeProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
