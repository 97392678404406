import Dialog from '@material-ui/core/Dialog';
import clsx from 'clsx';
import React, { FC, useEffect, useState } from 'react';
import { AiOutlineClose } from 'react-icons/ai';
import { BiImageAdd } from 'react-icons/bi';

import { Me } from '../../../models/UserModel';

type Props = {
  open: boolean;
  handleClose: () => void;
  me: Me | null;
  handleSharePost: (imageList: { imageUrl: string }[], content: string) => void;
};

export const ConCreateUserPostDialog: FC<Props> = ({
  open,
  handleClose,
  me,
  handleSharePost,
}) => {
  const [postContent, setPostContent] = useState('');
  const [inputImageList, setInputImageList] = useState([{ imageUrl: '' }]);

  //clear inputs
  useEffect(() => {
    setPostContent('');
    setInputImageList([{ imageUrl: '' }]);
  }, [open]);

  const handleContentChange = (e: React.ChangeEvent<any>) => {
    setPostContent(e.target.value);
  };

  //handle input change
  const handleImageInputChange = (e: React.ChangeEvent<any>, index: number) => {
    const list = [...inputImageList];
    list[index] = e.target.value;
    setInputImageList(list);
  };

  // handle click event of the Remove button
  const handleRemoveClick = (index: number) => {
    const list = [...inputImageList];
    list.splice(index, 1);
    setInputImageList(list);
  };

  // handle click event of the Add button
  const handleAddClick = () => {
    if (inputImageList.length <= 3) {
      setInputImageList([...inputImageList, { imageUrl: '' }]);
    }
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth
        PaperProps={{
          style: {
            backgroundColor: 'transparent',
          },
        }}
      >
        <div className="w-full bg-primary-default p-2 rounded-xl flex-col flex gap-2">
          <div className="flex justify-between items-center">
            <h2 className="text-lg">Create post</h2>
            <AiOutlineClose className="text-xl" onClick={handleClose} />
          </div>
          <div className="flex flex-col gap-2 border-t border-b border-primary-light py-3">
            <div className="flex gap-2 justify-start items-center">
              <img
                src={me?.imageUrl}
                alt=""
                className="w-10 h-10 rounded-full"
              />
              <div>
                <p className="text-sm">{`${me?.firstName} ${me?.lastName}`}</p>
                <p className="text-xs text-secondary-default text-opacity-50">{`@${me?.uniqueIdentifier.displayName}#${me?.uniqueIdentifier.tag}`}</p>
              </div>
            </div>
            <textarea
              placeholder={`Whats on your mind, ${me?.firstName}?`}
              className="w-full bg-primary-default min-h-24 p-2 text-sm rounded-xl rounded-br-none focus:outline-none"
              onChange={(e) => handleContentChange(e)}
              value={postContent}
            ></textarea>
            <div className="flex flex-col gap-2">
              {inputImageList.map((input, index) => {
                return (
                  <div className="flex gap-3 items-center w-full" key={index}>
                    <input
                      type="text"
                      placeholder="Image url"
                      value={input.imageUrl}
                      onChange={(e) => handleImageInputChange(e, index)}
                      className="w-full p-2 text-xs rounded-lg bg-primary-default border-primary-light border focus:outline-none "
                    />
                    <AiOutlineClose
                      className="text-base"
                      onClick={() => handleRemoveClick(index)}
                    />
                  </div>
                );
              })}
            </div>
            <div
              className="flex items-center justify-center gap-2 cursor-pointer select-none"
              onClick={handleAddClick}
            >
              <p className="text-xs">Add image (max 4)</p>
              <BiImageAdd className="text-lg" />
            </div>
          </div>

          <button
            className={clsx('text-sm', {
              'text-light-default text-opacity-50 cursor-default':
                postContent === '',
            })}
            onClick={() => handleSharePost(inputImageList, postContent)}
            disabled={postContent === ''}
          >
            Share post
          </button>
        </div>
      </Dialog>
    </div>
  );
};
