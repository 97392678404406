import { useSnackbar } from 'notistack';
import { useCallback } from 'react';

type SnackbarArgs = {
  variant: 'default' | 'success' | 'error' | 'warning' | 'info' | undefined;
  message: string;
};

export const useConSnackbar = (): [(args: SnackbarArgs) => void] => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const handleDismiss = useCallback(() => {
    closeSnackbar();
  }, [closeSnackbar]);

  return [
    ({ variant, message }: SnackbarArgs) => {
      enqueueSnackbar(message, {
        variant,
      });
    },
  ];
};
