import './Layout.scss';

import React, { FC } from 'react';

import { App } from '../../../../App';
import { Sidebar } from '../../shared/Navigation/Sidebar/Sidebar';
import { ConHeader } from '../ConHeader/ConHeader';

export const Layout: FC = () => {
  return (
    <div className="flex w-full h-full">
      <Sidebar />
      <div className="flex flex-col h-full w-full">
        <ConHeader />
        <App />
      </div>
    </div>
  );
};
