import Dialog from '@material-ui/core/Dialog';
import clsx from 'clsx';
import React, { FC, useEffect, useState } from 'react';
import { AiOutlineClose } from 'react-icons/ai';
import useSWR from 'swr';

import { Me } from '../../../models/UserModel';

type Props = {
  open: boolean;
  handleClose: () => void;
  me: Me | null;
  handleCreateForum: (
    name: string,
    iconUrl: string,
    imageUrl: string,
    description: string,
    tags: number[]
  ) => void;
};

export const CreateForumDialog: FC<Props> = ({
  open,
  handleClose,
  me,
  handleCreateForum,
}) => {
  const { data: tags, error: tagsError } = useSWR<string[]>(
    `${process.env.REACT_APP_API_ENDPOINT_FORUM}tags`
  );

  const [forumName, setForumName] = useState('');
  const [forumIconUrl, setForumIconUrl] = useState('');
  const [forumImageUrl, setForumImageUrl] = useState('');
  const [forumDescription, setForumDescription] = useState('');
  const [forumTags, setForumTags] = useState<number[]>([]);
  const [forumTagsInput, setForumTagsInput] = useState();

  //clear inputs
  useEffect(() => {
    setForumName('');
    setForumIconUrl('');
    setForumImageUrl('');
    setForumDescription('');
    setForumTags([]);
  }, [open]);

  const handleNameChange = (e: React.ChangeEvent<any>) => {
    setForumName(e.target.value);
  };
  const handleIconUrlChange = (e: React.ChangeEvent<any>) => {
    setForumIconUrl(e.target.value);
  };
  const handleImageUrlChange = (e: React.ChangeEvent<any>) => {
    setForumImageUrl(e.target.value);
  };
  const handleDescriptionChange = (e: React.ChangeEvent<any>) => {
    setForumDescription(e.target.value);
  };
  const valueChanged = (e: React.ChangeEvent<any>) => {
    setForumTagsInput(e.target.value);
  };

  const addTag = () => {
    if (
      forumTagsInput !== 'Select tag' &&
      !forumTags.includes(forumTagsInput ?? 0)
    ) {
      setForumTags((prev) => [...prev, forumTagsInput ?? 0]);
    }
  };

  const removeTag = (tagName: number) => {
    setForumTags((prev) => prev.filter((tag) => tag !== tagName));
  };

  const checkForInputs = () => {
    return (
      forumName !== '' &&
      forumIconUrl !== '' &&
      forumImageUrl !== '' &&
      forumDescription !== ''
    );
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth
        PaperProps={{
          style: {
            backgroundColor: 'transparent',
          },
        }}
      >
        <div className="w-full bg-primary-default p-2 rounded-xl flex-col flex gap-2">
          <div className="flex justify-between items-center">
            <h2 className="text-lg">Create Forum</h2>
            <AiOutlineClose className="text-xl" onClick={handleClose} />
          </div>
          <div className="flex flex-col border-t border-b border-primary-light py-3 gap-5">
            <div className="flex gap-2 justify-start items-center">
              <img
                src={me?.imageUrl}
                alt=""
                className="w-10 h-10 rounded-full object-cover"
              />
              <div>
                <p className="text-sm">{`${me?.firstName} ${me?.lastName}`}</p>
                <p className="text-xs text-secondary-default text-opacity-50">{`@${me?.uniqueIdentifier.displayName}#${me?.uniqueIdentifier.tag}`}</p>
              </div>
            </div>
            <input
              type="text"
              className="w-full bg-primary-default min-h-24 p-2 text-sm rounded-br-none focus:outline-none border-b border-primary-light"
              placeholder="Forum name *"
              onChange={(e) => handleNameChange(e)}
              value={forumName}
            />
            <input
              type="text"
              className="w-full bg-primary-default min-h-24 p-2 text-sm rounded-br-none focus:outline-none border-b border-primary-light"
              placeholder="Forum icon (url - .jpg, .png) *"
              onChange={(e) => handleIconUrlChange(e)}
              value={forumIconUrl}
            />
            <input
              type="text"
              className="w-full bg-primary-default min-h-24 p-2 text-sm rounded-br-none focus:outline-none border-b border-primary-light"
              placeholder="Forum cover image (url - .jpg, .png) *"
              onChange={(e) => handleImageUrlChange(e)}
              value={forumImageUrl}
            />
            <textarea
              placeholder="What is this forum about?"
              className="w-full bg-primary-default min-h-24 p-2 text-sm rounded-br-none focus:outline-none border-b border-primary-light"
              onChange={(e) => handleDescriptionChange(e)}
              value={forumDescription}
            ></textarea>
            <div className="flex gap-3">
              <select
                className="w-full bg-primary-default min-h-24 p-2 text-sm rounded-br-none focus:outline-none border-b border-primary-light text-light-default "
                onChange={valueChanged}
                value={forumTagsInput}
              >
                <option>Select tag</option>
                {tags?.map((tag, index) => {
                  return (
                    <option value={index} key={index} className="text-sm">
                      {tag}
                    </option>
                  );
                })}
              </select>
              <button onClick={addTag} className="w-1/5 focus:outline-none">
                Add
              </button>
            </div>
            <div className="flex flex-col">
              <p>Tags:</p>
              <div className="flex gap-2 flex-wrap">
                {forumTags.map((tag) => {
                  return (
                    tags && (
                      <div
                        className="flex rounded-md p-3 hover:bg-primary-light justify-center items-center gap-2 cursor-pointer"
                        key={tag}
                        onClick={() => removeTag(tag)}
                      >
                        <p className="text-sm">{tags[tag]}</p>
                      </div>
                    )
                  );
                })}
              </div>
            </div>
          </div>

          <button
            className={clsx('text-lg', {
              'text-light-default text-opacity-50 cursor-default': !checkForInputs(),
            })}
            onClick={() =>
              handleCreateForum(
                forumName,
                forumIconUrl,
                forumImageUrl,
                forumDescription,
                forumTags
              )
            }
            disabled={!checkForInputs()}
          >
            Create forum
          </button>
        </div>
      </Dialog>
    </div>
  );
};
