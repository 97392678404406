import React, { FC } from 'react';
import { Link } from 'react-router-dom';

import { ForumSimple } from '../../../../models/ForumModel';

type Props = {
  forum: ForumSimple;
};

export const PopularForumCard: FC<Props> = ({ forum }) => {
  return (
    <Link to={`/forum/${forum?.id}`} data-testid="link">
      <div className="flex items-center gap-3 rounded-md p-3 hover:bg-primary-light hover:bg-opacity-40 cursor-pointer">
        <img
          src={forum.iconUrl}
          className="h-12 w-12 rounded-full object-cover"
        />
        <div className="flex flex-col">
          <p data-testid="name">{forum.name}</p>
          <div className="flex gap-2">
            <p
              className="text-xs font-bold text-light-dark"
              data-testid="numOfSubscribers"
            >
              {forum.numOfSubscribers}
            </p>
            <p className="text-xs text-light-default text-opacity-50">
              followers
            </p>
          </div>
        </div>
      </div>
    </Link>
  );
};
