import React, { FC } from 'react';

type Props = {
  className?: string;
};

export const Card: FC<Props> = ({ className, children }) => {
  return (
    <div className={`bg-primary-light rounded-md p-2 shadow-2xl ${className}`}>
      {children}
    </div>
  );
};
