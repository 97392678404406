import './Sidebar.scss';

import React, { FC, useState } from 'react';
import {
  AiOutlineClose,
  AiOutlineHome,
  AiOutlineMessage,
  AiOutlineSearch,
} from 'react-icons/ai';
import { FaAccessibleIcon, FaRegUser } from 'react-icons/fa';
import { FiLogIn } from 'react-icons/fi';
import { MdForum } from 'react-icons/md';
import { Link } from 'react-router-dom';
import { useRecoilState } from 'recoil';

import {
  isLoggedInState,
  sideBarShownState,
} from '../../../../../state/atoms/atoms';
import { ConLink } from '../../../home/ConLink/ConLink';
import { ConSearchDialog } from '../../ConSearchDialog';

export const Sidebar: FC = () => {
  const [showMenu, setShowMenu] = useRecoilState(sideBarShownState);
  const [isLoggedIn, setIsLoggedIn] = useRecoilState(isLoggedInState);
  const [dialogOpen, setDialogOpen] = useState(false);

  const handleDialogOpen = () => {
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  return (
    <nav className={showMenu ? 'nav-menu active shadow-2xl' : 'nav-menu'}>
      <div className="nav-menu-title">
        <Link
          to="#"
          className="close-btn"
          onClick={() => {
            setShowMenu(false);
          }}
        >
          <AiOutlineClose className="icon" />
        </Link>
        <ConLink to={isLoggedIn ? '/' : '/'}>
          <FaAccessibleIcon
            className="text-3xl my-5 text-secondary-default"
            onClick={() => setShowMenu(false)}
          />
        </ConLink>
        <div className="text-secondary-default my-4 flex flex-col gap-1 justify-center items-center">
          {isLoggedIn && (
            <AiOutlineSearch className="text-3xl" onClick={handleDialogOpen} />
          )}
          <ConSearchDialog open={dialogOpen} handleClose={handleDialogClose} />
        </div>
      </div>
      {!isLoggedIn ? (
        <ul className="menu-items">
          <li>
            <ConLink to="/login" text="Login">
              <FiLogIn className="icon" onClick={() => setShowMenu(false)} />
            </ConLink>
          </li>
        </ul>
      ) : (
        <ul className="menu-items">
          <li>
            <ConLink to="/" text="Home">
              <AiOutlineHome
                className="icon"
                onClick={() => setShowMenu(false)}
              />
            </ConLink>
          </li>
          <li>
            <ConLink to="/profile" text="Profile">
              <FaRegUser className="icon" onClick={() => setShowMenu(false)} />
            </ConLink>
          </li>
          <li>
            <ConLink to="/messages" text="Messages" exact={false}>
              <AiOutlineMessage
                className="icon"
                onClick={() => setShowMenu(false)}
              />
            </ConLink>
          </li>
          <li>
            <ConLink to="/forum" text="Forum">
              <MdForum className="icon" onClick={() => setShowMenu(false)} />
            </ConLink>
          </li>
          {/* <li>
            <ConLink to="/matchmaking" text="Match Making">
              <FaGamepad className="icon" onClick={() => setShowMenu(false)} />
            </ConLink>
          </li> */}
        </ul>
      )}
    </nav>
  );
};
