import { Months } from '../data/month';

export const formatDate = (timestamp: number | undefined) => {
  let time = 0;
  let d = 0,
    h,
    m,
    s;

  if (timestamp) time = new Date().getTime() - timestamp;

  s = Math.floor(time / 1000);
  m = Math.floor(s / 60);
  s = s % 60;
  h = Math.floor(m / 60);
  m = m % 60;
  d = Math.floor(h / 24);
  h = h % 24;

  const timeSinceString =
    (d > 0 ? d + 'd ' : '') +
    (h > 0 ? h + 'h ' : '') +
    (d > 0 ? '' : m + 'm ') +
    'ago';

  return timeSinceString;
};

export const formatJoinedAt = (timestamp: number) => {
  const date = new Date(timestamp);

  return Months[date.getMonth()] + ' ' + date.getFullYear();
};
