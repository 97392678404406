import { useEffect, useState } from 'react';

import { UserSearchModel, UserSearchResultModel } from '../models/UserModel';
import { initUserApi } from '../utils/userUtils';

export const useUserSearch = (
  searchValue: string,
  pageNumber: number,
  pageSize: number,
  searchUser: boolean
) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [users, setUsers] = useState<UserSearchModel[] | null>(null);
  const [hasMore, setHasMore] = useState(false);

  useEffect(() => {
    setUsers(null);
  }, [searchValue]);

  useEffect(() => {
    setLoading(true);
    setError(false);

    if (searchValue === '' || searchValue.length <= 0) {
      setUsers(null);
      return;
    }
    if (searchUser) {
      initUserApi()
        .get<UserSearchResultModel>(`/user/search/${pageNumber}`, {
          params: {
            searchValue: searchValue,
            pageSize: pageSize,
          },
        })
        .then((res) => {
          setUsers(res.data.users);
          setLoading(false);
        })
        .catch((e) => {
          console.log(e);
          setError(true);
          setLoading(false);
        });
    } else {
      setUsers(null);
    }
  }, [pageNumber, searchValue, searchUser]);

  return { loading, error, users, hasMore };
};
