import clsx from 'clsx';
import React, { FC, useState } from 'react';
import { AiOutlineArrowUp } from 'react-icons/ai';
import { BsFillReplyFill } from 'react-icons/bs';

type ActionBarProps = {
  likes: number;
  handleAddComment: (value: string) => void;
  isLiked: boolean;
  handleLikeComment: () => void;
};

export const CommentActionBar: FC<ActionBarProps> = ({
  likes,
  handleAddComment,
  isLiked,
  handleLikeComment,
}) => {
  const [replyValue, setReplyValue] = useState('');
  const [replyBoxShown, setreplyBoxShown] = useState(false);

  return (
    <div className="flex flex-col">
      <div className="flex px-2 py-2 gap-2 justify-start items-center text-light-default">
        <AiOutlineArrowUp
          className={clsx('text-xs text-secondary-default cursor-pointer', {
            'text-light-light': !isLiked,
          })}
          onClick={handleLikeComment}
        />
        <p className="text-xs">{likes}</p>
        <p
          className="text-xs flex justify-center items-center gap-1 text-light-default text-opacity-60 hover:text-opacity-100 cursor-pointer"
          onClick={() => setreplyBoxShown(!replyBoxShown)}
        >
          reply
          <BsFillReplyFill />
        </p>
      </div>
      {replyBoxShown && (
        <div className="flex flex-col justify-center w-full md:w-1/2 ml-2 rounded-md border border-light-light border-opacity-50">
          <textarea
            className=" bg-primary-light rounded-t-md p-3 text-xs outline-none"
            placeholder="Add a comment..."
            value={replyValue}
            onChange={(e) => setReplyValue(e.target.value)}
          ></textarea>
          <div className="flex justify-end h-full border-t rounded-b-md border-light-light border-opacity-50 bg-primary-light">
            <button
              className={clsx('text-xs p-1 m-1 border rounded-lg', {
                'text-light-default text-opacity-50 cursor-default':
                  replyValue === '',
              })}
              onClick={() => {
                handleAddComment(replyValue);
                setreplyBoxShown(false);
              }}
              disabled={replyValue.trim() === ''}
            >
              Add reply
            </button>
          </div>
        </div>
      )}
    </div>
  );
};
