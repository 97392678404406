import React from 'react';
import { FC } from 'react';
import { Redirect } from 'react-router-dom';

interface Props {
  name?: string;
  // any other props that come into the component
}

export const UserSetupRoute: FC<Props> = ({ children }) => {
  return (
    <>
      {localStorage.getItem('con_userIsSetup') === '0' && (
        <Redirect to={{ pathname: '/user/setup' }} />
      )}
      {children}
    </>
  );
};
