import './NavItem.scss';

import React, { FC } from 'react';
import { useRecoilState } from 'recoil';

import { profileNavMenuState } from '../../../../../state/atoms/atoms';

type Props = {
  icon: any;
};

export const NavItem: FC<Props> = ({ icon, children }) => {
  const [open, setOpen] = useRecoilState(profileNavMenuState);
  return (
    <li className="nav-item">
      {/* <a className="icon-button" onClick={() => setOpen(!open)}>
        {icon}
      </a> */}
      <img
        src={icon}
        alt=""
        className="w-10 h-10 rounded-full cursor-pointer object-cover"
        onClick={() => setOpen(!open)}
      />
      {open && children}
    </li>
  );
};
