import Dialog from '@material-ui/core/Dialog';
import clsx from 'clsx';
import React, { FC, useEffect, useState } from 'react';
import { AiOutlineClose } from 'react-icons/ai';

import { Me } from '../../../../models/UserModel';

type Props = {
  open: boolean;
  handleClose: () => void;
  me: Me | null;
  handleAddComment: (content: string) => void;
};

export const UserCommentDialog: FC<Props> = ({
  open,
  handleClose,
  me,
  handleAddComment,
}) => {
  const [postContent, setPostContent] = useState('');

  //clear inputs
  useEffect(() => {
    setPostContent('');
  }, [open]);

  const handleContentChange = (e: React.ChangeEvent<any>) => {
    setPostContent(e.target.value);
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth
        PaperProps={{
          style: {
            backgroundColor: 'transparent',
          },
        }}
      >
        <div className="w-full bg-primary-default p-2 rounded-xl flex-col flex gap-2 relative">
          <AiOutlineClose
            className="text-xl absolute top-3 right-3"
            onClick={handleClose}
          />
          <div className="flex flex-col gap-2 border-b border-primary-light py-2">
            <div className="flex gap-2 justify-start items-center">
              <img
                src={me?.imageUrl}
                alt=""
                className="w-10 h-10 rounded-full object-cover"
              />
              <div>
                <p className="text-sm">{`${me?.firstName} ${me?.lastName}`}</p>
                <p className="text-xs text-secondary-default text-opacity-50">{`@${me?.uniqueIdentifier.displayName}#${me?.uniqueIdentifier.tag}`}</p>
              </div>
            </div>
            <textarea
              placeholder={`Thats nice, I like it!`}
              className="w-full bg-primary-default min-h-24 p-2 text-sm rounded-xl rounded-br-none focus:outline-none"
              onChange={(e) => handleContentChange(e)}
              value={postContent}
            ></textarea>
          </div>

          <button
            className={clsx('text-sm', {
              'text-light-default text-opacity-50 cursor-default':
                postContent === '',
            })}
            disabled={postContent === ''}
            onClick={() => handleAddComment(postContent)}
          >
            Add Comment
          </button>
        </div>
      </Dialog>
    </div>
  );
};
