import { http } from './helpers/http.helper';

export default async (input: RequestInfo, init?: RequestInit) => {
  try {
    const response = await http(input, {
      ...init,
      credentials: 'same-origin',
    });
    const body = await response.json();
    return body;
  } catch (e) {
    //Error handling
  }
};
