import './ConButton.scss';

import React, { FC } from 'react';

type Props = {
  text?: string;
  onClick?: () => void;
  fullWidth?: boolean;
  outlined?: boolean;
  disabled?: boolean;
};

export const ConButton: FC<Props> = ({
  text,
  onClick,
  outlined = false,
  fullWidth = false,
  children,
  disabled = false,
}) => {
  return (
    <div
      className={
        'btn-container ' +
        (outlined ? 'outlined ' : '') +
        (fullWidth ? 'fullWidth ' : 'fixedWidth ')
      }
    >
      <button onClick={onClick} type="button" disabled={disabled}>
        <span className={outlined ? 'btn-text-outlined ' : 'btn-text'}>
          {text}
        </span>
        {children}
      </button>
    </div>
  );
};
