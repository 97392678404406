import React, { FC } from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';

import { checkAuth } from '../../../../utils/authUtils';
import { UserSetupRoute } from './UserSetupRoute';

type AuthRouteProps = {
  path: RouteProps['path'];
  exact?: boolean;
};

export const AuthRoute: FC<AuthRouteProps> = ({
  children,
  exact,
  ...routeProps
}) => {
  return (
    <Route
      {...routeProps}
      exact={exact}
      render={() => {
        return checkAuth() ? (
          <UserSetupRoute>{children}</UserSetupRoute>
        ) : (
          <Redirect to={{ pathname: '/login' }} />
        );
      }}
    />
  );
};
