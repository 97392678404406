import './../styles/views/Login.scss';

import { Typography } from '@material-ui/core';
import React, { FC } from 'react';
import {
  FaAccessibleIcon,
  FaFacebookSquare,
  FaTwitch,
  FaTwitter,
} from 'react-icons/fa';
import { FcGoogle, FcSteam } from 'react-icons/fc';

const Login: FC = ({}) => {
  return (
    <div className="container-login">
      <FaAccessibleIcon className="logo-login" />
      <div className="actions-container">
        <div className="flex flex-col md:w-96 p-5 md:p-10 gap-4 bg-primary-light rounded-md">
          <p className="text-xl">Log in to continue ...</p>
          <a href={process.env.REACT_APP_GOOGLE_ENDPOINT}>
            <button className="w-full flex justify-start items-center gap-4 bg-secondary-default rounded-md p-2 text-primary-default text-base">
              <FcGoogle className="text-3xl" /> Continue with Google
            </button>
          </a>
          <p className="mt-8">Below options available soon</p>
          <button
            disabled
            className="w-full flex justify-start items-center gap-4 bg-secondary-default rounded-md p-2 text-primary-default text-base"
          >
            <FaFacebookSquare className="text-3xl text-logos-facebook" />{' '}
            Facebook (coming soon)
          </button>
          <button
            disabled
            className="w-full flex justify-start items-center gap-4 bg-secondary-default rounded-md p-2 text-primary-default text-base"
          >
            <FaTwitter className="text-3xl text-logos-twitter" />
            Twitter (coming soon)
          </button>
          <button
            disabled
            className="w-full flex justify-start items-center gap-4 bg-secondary-default rounded-md p-2 text-primary-default text-base"
          >
            <FcSteam className="text-3xl" />
            Steam (coming soon)
          </button>
          <button
            disabled
            className="w-full flex justify-start items-center gap-4 bg-secondary-default rounded-md p-2 text-primary-default text-base"
          >
            <FaTwitch className="text-3xl text-logos-twitch" />
            Twitch (coming soon)
          </button>
        </div>
      </div>
      <div className="p-5">
        <Typography
          className="header-title"
          variant="subtitle1"
          color="textSecondary"
        >
          Don't have an account yet? Sign in to get started
        </Typography>
      </div>
    </div>
  );
};

export default Login;
