import './ConCheckbox.scss';

import React, { FC } from 'react';

import { Checkbox } from '../../../../models/Checkbox';

type Props = {
  box: Checkbox;
  onToggleCheckBox: (id: number) => void;
};

export const ConCheckbox: FC<Props> = ({ box, onToggleCheckBox }) => {
  return (
    <label className="container" onChange={() => onToggleCheckBox(box.id)}>
      {box.text}
      <input type="checkbox" checked={box.checked} />
      <span className="checkmark"></span>
    </label>
  );
};
