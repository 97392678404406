import './../styles/views/SpecificPost.scss';

import { Typography } from '@material-ui/core';
import clsx from 'clsx';
import React, { useState } from 'react';
import { AiFillHeart, AiOutlineHeart } from 'react-icons/ai';
import { BiShare } from 'react-icons/bi';
import { VscComment } from 'react-icons/vsc';
import { Link, useParams } from 'react-router-dom';
import useSWR, { mutate } from 'swr';

import { CommentSection } from '../components/feature/forum/CommentSection/CommentSection';
import LoadingSpinner from '../components/feature/shared/LoadingSpinner/LoadingSpinner';
import { ShareDialog } from '../components/feature/sharing/ShareDialog';
import { ForumSimple } from '../models/ForumModel';
import { PostDetailed } from '../models/PostModel';
import { Me } from '../models/UserModel';
import { initForumApi } from '../utils/forumUtils';
import { formatDate } from '../utils/utils';

type ParamTypes = {
  id: string;
};

export const SpecificPost = () => {
  const { id } = useParams<ParamTypes>();
  const [newComment, setNewComment] = useState('');
  const [shareDialogOpen, setShareDialogOpen] = useState(false);
  const { data: me } = useSWR<Me>(
    `${process.env.REACT_APP_API_ENDPOINT_USER}user/me`
  );
  const { data: post, error } = useSWR<PostDetailed>(
    `${process.env.REACT_APP_API_ENDPOINT_FORUM}post/${id}/detailed`
  );
  const { data: forum } = useSWR<ForumSimple>(
    `${process.env.REACT_APP_API_ENDPOINT_FORUM}forum/${post?.forumId}`
  );

  const onPostComment = () => {
    const comment = { content: newComment };
    initForumApi()
      .post(`comment/${post?.id}`, comment)
      .then((res) => {
        setNewComment('');
        mutate(
          `${process.env.REACT_APP_API_ENDPOINT_FORUM}post/${id}/detailed`
        );
      })
      .catch((e) => console.log(e));
  };

  const onPostLike = () => {
    initForumApi()
      .post(`/post/${post?.id}/like`)
      .then(() => {
        mutate(
          `${process.env.REACT_APP_API_ENDPOINT_FORUM}post/${id}/detailed`
        );
        mutate(`${process.env.REACT_APP_API_ENDPOINT_USER}user/me`);
      });
  };

  return (
    <div className="w-full  lg:mb-5 flex flex-col items-center justify-center gap-2">
      <div className="w-full xl:w-4/5">
        <Typography variant="h6">{post?.title}</Typography>
      </div>
      <div className="w-full xl:w-4/5">
        <p className="text-light-default text-sm">{post?.content}</p>
      </div>
      <div className="flex flex-col gap-3 w-full xl:w-4/5">
        <div className="images-container">
          {post?.imageUrls.map((image, i) => {
            return (
              image && (
                <img
                  src={image}
                  alt=""
                  className="w-full lg:rounded-2xl"
                  key={i}
                />
              )
            );
          })}
        </div>
        <div className="flex justify-evenly">
          <div
            className="flex items-center cursor-pointer"
            onClick={onPostLike}
          >
            {me?.likedForumPosts.includes(id) ? (
              <AiFillHeart className="text-lg mr-2" />
            ) : (
              <AiOutlineHeart className="text-lg mr-2" />
            )}
            <p className="text-sm">{post?.likes} Likes</p>
          </div>
          <div className="flex items-center">
            <VscComment className="text-lg mr-2" />
            <p className="text-sm">{post?.numOfComments} Comments</p>
          </div>
          <div
            className="flex items-center cursor-pointer"
            onClick={() => setShareDialogOpen(true)}
          >
            <BiShare className="text-lg mr-2" />{' '}
            <p className="text-sm">Share</p>
          </div>
        </div>
        <ShareDialog
          open={shareDialogOpen}
          content={
            'Wow look at this cool post i found on connectsocial.online\n\n'
          }
          // Has to code URL like this since the current url is /profile and not /displayname/tag
          shareUrl={`https://connectsocial.online/forum/post/${post?.id ?? ''}`}
          handleClose={() => setShareDialogOpen(false)}
        />

        <div className="flex items-center justify-center gap-3">
          <img
            src={forum?.iconUrl}
            alt=""
            className="h-8 w-8 rounded-full object-cover"
          />
          <Link to={`/forum/${post?.forumId}`}>
            <p className="text-xs text-opacity-60 hover:text-opacity-95 text-secondary-default">
              c/{forum?.name}
            </p>
          </Link>
          <p className="text-xs opacity-60">-</p>
          <p className="text-xs opacity-60">{formatDate(post?.createdAt)}</p>
          <p className="text-xs opacity-60">-</p>
          <Link to={`/u/${post?.author.displayName}/${post?.author.tag}`}>
            <p className="text-xs text-opacity-60 hover:text-opacity-95 text-secondary-default">
              {`u/${post?.author.displayName}#${post?.author.tag}`}
            </p>
          </Link>
        </div>
      </div>

      <div>{!post && <LoadingSpinner />}</div>

      <div className="flex flex-col justify-center w-11/12 rounded-md border border-light-light border-opacity-50 xl:w-4/5">
        <textarea
          className=" bg-primary-light rounded-t-md p-3 text-xs outline-none"
          placeholder="Add a comment..."
          value={newComment}
          onChange={(e) => setNewComment(e.target.value)}
        ></textarea>
        <div className="flex justify-end h-full border-t rounded-b-md border-light-light border-opacity-50 bg-primary-light">
          <button
            className={clsx('text-xs p-1 m-1 border rounded-lg', {
              'text-light-default text-opacity-50 cursor-default':
                newComment.trim() === '',
            })}
            onClick={onPostComment}
            disabled={newComment.trim() === ''}
          >
            Comment
          </button>
        </div>
      </div>
      <div className="w-full xl:w-4/5">
        <CommentSection
          comments={post?.comments ?? []}
          postId={id}
          likedComments={me?.likedForumComments ?? []}
        />
      </div>
    </div>
  );
};
