// import { appSettings, getToken, setAppSettings } from './app-settings.helper';
// import { default as adService } from '~/ad-b2c/ADService';
// import jwt_decode, { JwtPayload } from 'jwt-decode';

type IHeaders = RequestInit['headers'];

async function addHeaders(
  extraHeaders: IHeaders,
  withAuth = true
): Promise<IHeaders> {
  let defaultHeaders: IHeaders = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  };

  if (withAuth) {
    const token = localStorage.getItem('con_token');

    if (token) {
      defaultHeaders = {
        ...defaultHeaders,
        Authorization: `Bearer ${token}`,
      };
    }
  }
  return { ...defaultHeaders, ...extraHeaders };
}

export async function http(
  input: RequestInfo,
  init: RequestInit = {}
): Promise<Response> {
  return fetch(input, {
    ...init,
    headers: await addHeaders(init.headers),
    credentials: 'include',
  });
}
