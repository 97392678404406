import './FeedPostCard.scss';

import React, { FC } from 'react';
import { AiFillHeart, AiOutlineHeart } from 'react-icons/ai';
import { BiShare } from 'react-icons/bi';
import { VscComment } from 'react-icons/vsc';
import { Link } from 'react-router-dom';

import { FeedPost } from '../../../../models/FeedModel';
import { Author } from '../../../../models/UserPostModel';
import { formatDate } from '../../../../utils/utils';

type Props = {
  post: FeedPost;
  lastElRef?: any;
  isLikingPost: boolean;
  onLikePost: (id: string) => void;
  onClickShare: () => void;
};

export const FeedPostCard: FC<Props> = ({
  post,
  lastElRef,
  isLikingPost,
  onLikePost,
  onClickShare,
}) => {
  return (
    <div
      ref={lastElRef}
      className="flex flex-col w-full xl:w-2/3 bg-primary-light p-2 rounded-md gap-2"
    >
      {post.baseType === 'UserPost' ? (
        <UserPostAutherCard
          author={post.author}
          iconUrl={post.icon}
          userName={post.title}
          createdAt={post.createdAt}
        />
      ) : (
        <ForumPostAutherCard
          author={post.author}
          iconUrl={post.icon}
          forumName={post.forumName}
          parentId={post.parentId}
          createdAt={post.createdAt}
        />
      )}
      {/* content and title */}
      <Link
        to={
          post.baseType === 'UserPost'
            ? `/u/${post.author.displayName}/${post.author.tag}/${post.id}`
            : `/forum/post/${post.id}`
        }
      >
        <div className="flex flex-col">
          <p className="text-lg font-bold">{post.title}</p>
          <p className="text-light-default text-sm">{post.content}</p>
          {/* Images */}
          <div className="images-container">
            {post.imageUrls.map((image, index) => {
              return (
                image && (
                  <img
                    src={image}
                    alt=""
                    className="rounded-md w-full"
                    key={index}
                  />
                )
              );
            })}
          </div>
        </div>
      </Link>

      <div className="w-full flex flex-row justify-start gap-4 px-5 border-t border-secondary-default border-opacity-40">
        <div
          className="flex justify-center items-center gap-2 cursor-pointer hover:bg-primary-default p-2 rounded-md"
          onClick={() => onLikePost(post.id)}
        >
          {isLikingPost ? (
            <AiFillHeart className="text-lg" />
          ) : (
            <AiOutlineHeart className="text-lg" />
          )}
        </div>
        <Link
          to={
            post.baseType === 'UserPost'
              ? `/u/${post.author.displayName}/${post.author.tag}/${post.id}`
              : `/forum/post/${post.id}`
          }
        >
          <div className="flex gap-2 items-center cursor-pointer hover:bg-primary-default p-2 rounded-md">
            <p className="text-xs">{post.numOfComments}</p>
            <VscComment className="text-lg" />
          </div>
        </Link>
        <div
          className="flex gap-2 items-center cursor-pointer hover:bg-primary-default p-2 rounded-md"
          onClick={onClickShare}
        >
          <BiShare className="text-lg" />
        </div>
      </div>
    </div>
  );
};

type FPAProps = {
  author: Author;
  parentId: string;
  iconUrl: string;
  forumName: string;
  createdAt: number;
};

const ForumPostAutherCard: FC<FPAProps> = ({
  author,
  parentId,
  iconUrl,
  forumName,
  createdAt,
}) => {
  return (
    <div className="flex gap-1 w-full items-center">
      <Link to={`/forum/${parentId}`}>
        <img src={iconUrl} className="h-14 w-14 rounded-full object-cover" />
      </Link>
      <div className="flex flex-col">
        <Link to={`/forum/${parentId}`}>
          <p className="text-base cursor-pointer hover:bg-primary-default px-2 rounded-md hover:bg-opacity-40">{`c/${forumName}`}</p>
        </Link>
        <Link to={`/u/${author.displayName}/${author.tag}`}>
          <p className="text-sm text-opacity-70 text-secondary-dark cursor-pointer hover:bg-primary-default px-2 rounded-md hover:bg-opacity-40">{`@${author.displayName}#${author.tag}`}</p>
        </Link>
      </div>
    </div>
  );
};

type UPAProps = {
  author: Author;
  iconUrl: string;
  userName: string;
  createdAt: number;
};

const UserPostAutherCard: FC<UPAProps> = ({
  author,
  iconUrl,
  userName,
  createdAt,
}) => {
  return (
    <Link to={`/u/${author.displayName}/${author.tag}`}>
      <div className="flex items-center w-full justify-between">
        <div className="flex gap-3 cursor-pointer hover:bg-primary-default p-2 rounded-md hover:bg-opacity-40">
          <img src={iconUrl} className="h-14 w-14 rounded-full object-cover" />
          <div className="flex flex-col justify-center">
            <p className="text-base">{`${userName}`}</p>
            <p className="text-sm text-opacity-70 text-secondary-dark">{`@${
              author.displayName
            }#${author.tag} - ${formatDate(createdAt)}`}</p>
          </div>
        </div>
      </div>
    </Link>
  );
};
