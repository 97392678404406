import React, { FC } from 'react';
import { IoIosArrowForward } from 'react-icons/io';
import { Link } from 'react-router-dom';

import { MutualFollowersModel } from '../../../../models/UserModel';

type Props = {
  user: MutualFollowersModel;
};

export const FollowerCard: FC<Props> = ({ user }) => {
  return (
    <Link
      to={`/u/${user.uniqueIdentifier.displayName}/${user.uniqueIdentifier.tag}`}
    >
      <div className="flex gap-4 items-center w-full justify-between rounded-md p-2 hover:bg-primary-light hover:bg-opacity-40 cursor-pointer">
        <div className="flex items-center gap-2">
          <img
            src={user.imageUrl}
            className="w-10 h-10 rounded-full object-cover"
          />
          <p className="text-sm break-all">{`${user.uniqueIdentifier.displayName}#${user.uniqueIdentifier.tag}`}</p>
        </div>
        <IoIosArrowForward className="w-5 h-5 justify-end" />
      </div>
    </Link>
  );
};
