import React, { FC, useEffect, useRef, useState } from 'react';

import { Message } from '../../../models/chat/Message';
import { UniqueIdentifier } from '../../../models/UserModel';
import { CompareUID } from '../../../utils/helpers/UniqueIdentifier.helper';
import { ChatMessage } from './ChatMessage';

type SelectedChatProps = {
  messages?: Message[];
  chattingWith?: UniqueIdentifier;
  onClickSend: (e: any, content: string, recipient?: UniqueIdentifier) => void;
};

export const SelectedChat: FC<SelectedChatProps> = ({
  messages,
  chattingWith,
  onClickSend,
}) => {
  const [message, setMessage] = useState<string>('');

  const messagesEndRef = useRef<null | HTMLDivElement>(null);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'auto' });
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  return (
    <div className="w-full flex flex-col items-center justify-start bg-primary-light rounded-md p-2 pb-0">
      <div className="border-b w-full flex justify-center items-center">
        <p className="text-base md:text-xl py-2 ">
          {chattingWith == undefined
            ? ''
            : `${chattingWith?.displayName}#${chattingWith?.tag}`}
        </p>
      </div>
      <div className="chat-container">
        {messages?.map((m) => {
          return (
            <ChatMessage
              key={m.createdAt}
              message={m.content}
              me={!CompareUID(m.sender, chattingWith)}
            />
          );
        })}
        <div ref={messagesEndRef} />
      </div>
      <form
        className="w-full flex h-24 p-2"
        onSubmit={(e) => {
          onClickSend(e, message, chattingWith);
          setMessage('');
        }}
      >
        <textarea
          onKeyPress={(e) => {
            if (e.charCode === 13 && !e.shiftKey) {
              onClickSend(e, message, chattingWith);
              setMessage('');
            }
          }}
          placeholder="Type your message here"
          className="w-full h-full bg-primary-default rounded-xl text-sm focus:outline-none py-2 px-5 max-h-full"
          value={message}
          onChange={(event) => setMessage(event.target.value)}
        />
      </form>
    </div>
  );
};
