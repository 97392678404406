import React from 'react';
import useSWR from 'swr';

import { PopularForumsModel } from '../../../../models/ForumModel';
import { PopularForumCard } from './PopularForumCard';

export const PopularForums = () => {
  const { data, error } = useSWR<PopularForumsModel>(
    `${process.env.REACT_APP_API_ENDPOINT_FORUM}forum/popular/${1}/`
  );

  return (
    <div className="w-full max-h-1/2 bg-primary-light bg-opacity-30 rounded-xl p-2 flex flex-col gap-2">
      <p
        className="text-xl font-bold flex items-center gap-2"
        data-testid="title"
      >
        Popular forums
      </p>
      {data?.forums.map((forum) => {
        return <PopularForumCard forum={forum} key={forum.id} />;
      })}
    </div>
  );
};
