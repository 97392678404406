import './App.scss';

import React from 'react';
import { Route, Switch } from 'react-router-dom';

import { BasicLayout } from './components/feature/layout/Layout/BasicLayout';
import { AuthRoute } from './components/feature/shared/Navigation/AuthRoute';
import { UserSpecificPost } from './components/feature/user/UserSpecificPost/UserSpecificPost';
import { useLogin } from './hooks/useLogin';
import Forum from './views/Forum';
import { Home } from './views/Home';
import Login from './views/login';
import Messages from './views/Messages';
import Profile from './views/Profile';
import Register from './views/register';
import { SetupUser } from './views/SetupUser';
import { SpecificForum } from './views/SpecificForum';
import { SpecificPost } from './views/SpecificPost';
import { UserFollower } from './views/UserFollower';
import { UserProfile } from './views/UserProfile';

export const App = () => {
  useLogin();
  return (
    <div className="App">
      <Switch>
        {/* <Route path="/landing" component={LandingPage} exact /> */}
        {/* <AuthRoute path="/" exact> */}
        <Route path="/" exact={true}>
          {/* <BasicLayout> */}
          <Home />
          {/* </BasicLayout> */}
        </Route>
        {/* </AuthRoute> */}
        <Route path="/register" component={Register} />
        <Route path="/login" component={Login} />
        <AuthRoute path="/user/setup">
          <SetupUser />
        </AuthRoute>
        <AuthRoute path="/profile">
          <BasicLayout>
            <Profile />
          </BasicLayout>
        </AuthRoute>
        <AuthRoute path="/messages" exact={true}>
          <Messages />
        </AuthRoute>
        <AuthRoute path="/messages/:displayName/:tag" exact={true}>
          <Messages />
        </AuthRoute>
        <AuthRoute path="/forum" exact={true}>
          <BasicLayout>
            <Forum />
          </BasicLayout>
        </AuthRoute>
        <AuthRoute path="/forum/:id" exact={true}>
          <BasicLayout>
            <SpecificForum />
          </BasicLayout>
        </AuthRoute>
        <AuthRoute path="/forum/post/:id" exact={true}>
          <BasicLayout>
            <SpecificPost />
          </BasicLayout>
        </AuthRoute>
        {/* <AuthRoute path="/matchmaking">
          <MatchMaking />
        </AuthRoute> */}
        <AuthRoute path="/u/:displayName/:tag" exact={true}>
          <BasicLayout>
            <UserProfile />
          </BasicLayout>
        </AuthRoute>
        <AuthRoute path="/u/:displayName/:tag/:id" exact={true}>
          <BasicLayout>
            <UserSpecificPost />
          </BasicLayout>
        </AuthRoute>
        <AuthRoute path="/:followInfo/:displayName/:tag" exact={true}>
          <BasicLayout>
            <UserFollower />
          </BasicLayout>
        </AuthRoute>
        <Route
          path="*"
          component={() => {
            return <h1>Not found!</h1>;
          }}
        />
      </Switch>
    </div>
  );
};
