import clsx from 'clsx';
import React, { FC } from 'react';

import { Message } from '../../../models/chat/Message';
import { UniqueIdentifier } from '../../../models/UserModel';

type ChatCardProps = {
  imageUrl: string;
  uid: UniqueIdentifier;
  mostRecentMessage?: Message;
  selected?: boolean;
  onClick: (name: UniqueIdentifier) => void;
};

export const ChatCard: FC<ChatCardProps> = ({
  imageUrl,
  uid,
  mostRecentMessage,
  selected,
  onClick,
}) => {
  return (
    <div
      className={clsx(
        'flex flex-col items-center justify-center w-full p-1 gap-1',
        {
          'bg-secondary-default bg-opacity-20 rounded-md': selected,
        }
      )}
      onClick={() => onClick(uid)}
    >
      <img
        src={imageUrl}
        alt=""
        className="w-12 h-12 rounded-full object-cover"
      />
      <p className="hidden text-sm md:flex overflow-ellipsis whitespace-nowrap overflow-hidden break-all max-w-xxs">{`${uid.displayName}#${uid.tag}`}</p>
      <p className="hidden md:flex text-xs overflow-ellipsis whitespace-nowrap overflow-hidden break-all max-w-xxs">
        {mostRecentMessage?.content}
      </p>
    </div>
  );
};
