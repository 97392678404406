import clsx from 'clsx';
import { FC } from 'react';

type ChatMessageProps = {
  message: string;
  me: boolean;
};

export const ChatMessage: FC<ChatMessageProps> = ({ message, me }) => {
  // useEffect(() => {
  //   console.log(message, me);
  // }, []);
  return (
    <div
      className={clsx('rounded-xl py-2 ', {
        'self-end bg-secondary-default': me,
        'bg-secondary-dark self-start': !me,
      })}
      style={{ maxWidth: '80%', minWidth: '15%' }}
    >
      <p className="text-primary-light text-sm px-2 break-all">{message}</p>
    </div>
  );
};
