import React, { FC } from 'react';

import { Feed } from '../components/feature/home/Feed';
import { BasicLayout } from '../components/feature/layout/Layout/BasicLayout';
import { checkAuth } from '../utils/authUtils';
import LandingPage from './LandingPage';

export const Home: FC = ({}) => {
  // const [isLoggedIn, setIsLoggedIn] = useRecoilState(isLoggedInState);
  // const [showFeed, setShowFeed] = useState(true);
  // const { data: me, error: meError } = useSWR<Me>(
  //   `${process.env.REACT_APP_API_ENDPOINT_USER}user/me`
  // );
  // const [userInfo, setUserInfo] = useRecoilState(userInfoState);

  return (
    <div>
      {checkAuth() && (
        <BasicLayout>
          <div className="w-full flex">
            <Feed />
          </div>
        </BasicLayout>
      )}

      {!checkAuth() && <LandingPage />}
    </div>
  );
};
