import './ConLink.scss';

import React, { FC } from 'react';
import { Link, NavLink } from 'react-router-dom';

type Props = {
  text?: string;
  to: string;
  variant?: 'NavLink' | 'Link';
  exact?: false | true;
};

export const ConLink: FC<Props> = ({
  text,
  to,
  children,
  variant = 'NavLink',
  exact = true,
}) => {
  if (variant !== 'NavLink') {
    return (
      <Link to={to} className="flex flex-col items-center">
        {children}
        <span>{text}</span>
      </Link>
    );
  }
  return (
    <NavLink
      exact={exact}
      to={to}
      activeClassName="is-active"
      className="flex flex-col items-center gap-2"
    >
      {children}
      <span className="select-none">{text}</span>
    </NavLink>
  );
};
