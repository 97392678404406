import { selector } from 'recoil';

import { userInfoState } from '../atoms/atoms';

export const loggedInUID = selector({
  key: 'loggedInUID',
  get: ({ get }) => {
    const user = get(userInfoState);

    return user?.uniqueIdentifier;
  },
});

export const loggedInUser = selector({
  key: 'loggedInUser',
  get: ({ get }) => {
    const user = get(userInfoState);

    return user;
  },
});
