import React, { FC, useCallback, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import useSWR, { mutate } from 'swr';

import { useFeed } from '../../../hooks/useFeed';
import { FeedPost } from '../../../models/FeedModel';
import { Me } from '../../../models/UserModel';
import { initForumApi } from '../../../utils/forumUtils';
import { initUserApi } from '../../../utils/userUtils';
import LoadingSpinner from '../shared/LoadingSpinner/LoadingSpinner';
import { ShareDialog } from '../sharing/ShareDialog';
import { FeedPostCard } from './FeedPostCard/FeedPostCard';

export const Feed: FC = () => {
  const [pageNumber, setPageNumber] = useState(1);
  const { feedPosts, loading, hasMore } = useFeed(pageNumber);
  const [shareDialogOpen, setShareDialogOpen] = useState(false);
  const [postToShare, setPostToShare] = useState<FeedPost | undefined>();

  const { data: me, error: meError } = useSWR<Me>(
    `${process.env.REACT_APP_API_ENDPOINT_USER}user/me`
  );

  const onForumPostLike = (id: string) => {
    initForumApi()
      .post(`/post/${id}/like`)
      .then(() => {
        mutate(`${process.env.REACT_APP_API_ENDPOINT_USER}user/me`);
      });
  };

  const onUserPostLike = (id: string) => {
    initUserApi()
      .post(`/Post/${id}/like`, { postId: id })
      .then(() => {
        mutate(`${process.env.REACT_APP_API_ENDPOINT_USER}user/me`);
      });
  };

  /*
    next 20 lines of code creates the infinity scroll feeling.
  */
  const observer = useRef<IntersectionObserver>();
  const lastPostElementRef = useCallback(
    (node) => {
      if (loading) return;
      if (observer.current) {
        observer.current;
      }

      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setPageNumber((prevPageNumber) => prevPageNumber + 1);
        }
      });

      if (node) {
        observer.current.observe(node);
      }
    },
    [loading, hasMore]
  );

  return (
    <div className="flex flex-col p-5 w-full gap-4 justify-center  items-center">
      <p className="text-3xl font-bold w-full">Your Feed</p>
      {feedPosts && feedPosts?.length > 0 && (
        <div className="flex flex-col gap-4 items-center">
          {feedPosts?.map((post, index) => {
            if (feedPosts.length === index + 1) {
              return (
                <FeedPostCard
                  post={post}
                  key={post.id}
                  lastElRef={lastPostElementRef}
                  onLikePost={() =>
                    post.baseType === 'UserPost'
                      ? onUserPostLike(post.id)
                      : onForumPostLike(post.id)
                  }
                  isLikingPost={
                    (post.baseType === 'UserPost'
                      ? me?.likedUserPosts.includes(post.id)
                      : me?.likedForumPosts.includes(post.id)) ?? false
                  }
                  onClickShare={() => {
                    setPostToShare(post);
                    setShareDialogOpen(true);
                  }}
                />
              );
            } else {
              return (
                <FeedPostCard
                  post={post}
                  key={post.id}
                  onLikePost={() =>
                    post.baseType === 'UserPost'
                      ? onUserPostLike(post.id)
                      : onForumPostLike(post.id)
                  }
                  isLikingPost={
                    post.baseType === 'UserPost'
                      ? me?.likedUserPosts.includes(post.id) ?? false
                      : me?.likedForumPosts.includes(post.id) ?? false
                  }
                  onClickShare={() => {
                    setPostToShare(post);
                    setShareDialogOpen(true);
                  }}
                />
              );
            }
          })}
        </div>
      )}
      <ShareDialog
        open={shareDialogOpen}
        content={
          'Wow look at this cool post i found on connectsocial.online\n\n'
        }
        // Has to code URL like this since the current url is /profile and not /displayname/tag
        shareUrl={
          postToShare?.baseType === 'UserPost'
            ? `https://connectsocial.online/u/${postToShare?.author.displayName}/${postToShare?.author.tag}/${postToShare?.id}`
            : `https://connectsocial.online/forum/post/${postToShare?.id ?? ''}`
        }
        handleClose={() => setShareDialogOpen(false)}
      />
      {feedPosts && feedPosts?.length === 0 && (
        <div className="flex flex-col justify-center items-center w-full">
          <div className="flex flex-col w-1/2 gap-4">
            <p className="text-5xl">Welcome to Connect!</p>
            <p className="text-base w-full text-light-light">
              This is your main feed, which will feed you popular posts from the
              forums and users YOU follow.
            </p>
            <p className="text-sm w-full text-light-light">
              You can browse popular forum posts{' '}
              <Link to="/forum" className="text-secondary-default">
                here
              </Link>{' '}
              or find specific forums, posts or users using the search 🔎
              feature , which can be found in the sidebar.
            </p>
            <img
              src="https://media.discordapp.net/attachments/806480138266738688/837301513360900136/57kzj6.png.jpg?width=472&height=465"
              className="rounded-md"
            />
          </div>
        </div>
      )}
      <div className="flex w-full justify-center items-center">
        {loading && <LoadingSpinner />}
      </div>
    </div>
  );
};
