import { Dialog } from '@material-ui/core';
import React, { FC } from 'react';

import { FacebookShareButton } from './FacebookShareButton';
import { TwitterShareButton } from './TwitterShareButton';

type Props = {
  open: boolean;
  content: string;
  shareUrl: string;
  handleClose: () => void;
};

export const ShareDialog: FC<Props> = ({
  open,
  content,
  shareUrl,
  handleClose,
}) => {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      PaperProps={{
        style: {
          backgroundColor: 'transparent',
        },
      }}
    >
      <div className="bg-primary-default p-2">
        <p>Share post</p>
        <div className="flex justify-evenly p-5 text-center bg-primary-default">
          <div className="flex flex-col justify-between items-center mx-5 gap-2">
            <TwitterShareButton content={content} shareUrl={shareUrl} />
            <p>Share on Twitter</p>
          </div>
          <div className="flex flex-col justify-between items-center mx-5 gap-2">
            <FacebookShareButton content={content} shareUrl={shareUrl} />
            <p>Share on Facebook</p>
          </div>
        </div>
      </div>
    </Dialog>
  );
};
