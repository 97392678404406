import { HubConnection, HubConnectionBuilder } from '@microsoft/signalr';
import axios from 'axios';
import jwt_decode from 'jwt-decode';
import { useHistory } from 'react-router-dom';

import { DecodedTokenType, initAuthApi } from './authUtils';

export const initChatApi = () => {
  const token = localStorage.getItem('con_token');
  const history = useHistory();
  // const [isLoggedIn, setIsLoggedIn] = useRecoilState(isLoggedInState);

  const headers: HeadersInit = {
    // ifi
    Accept: 'application/json',
  };

  if (token) {
    const decoded: DecodedTokenType = jwt_decode(token);

    if (decoded.exp < new Date().getTime() / 1000) {
      initAuthApi()
        .get('/Refresh', { withCredentials: true })
        .then((res) => {
          localStorage.setItem('con_token', res.data);
        })
        .catch((e) => {
          console.log(e);
          initAuthApi().get('Logout');
        });
    }
  }

  if (token) {
    headers.Authorization = `Bearer ${token}`;
  }

  const client = axios.create({
    baseURL: process.env.REACT_APP_API_ENDPOINT_CHAT,
    timeout: 31000,
    headers: headers,
    withCredentials: true,
  });
  return client;
};

let client: HubConnection;

export const buildSignalRConnection = async () => {
  const token = localStorage.getItem('con_token');
  if (!token) {
    return;
  }

  client = new HubConnectionBuilder()
    .withUrl(process.env.REACT_APP_API_ENDPOINT_CHAT + 'chathub', {
      accessTokenFactory: () => token,
    })
    .build();
  await client.start();

  console.log('Connected!');
};

export const setMessageListener = (
  callback: (sender: string, message: string) => void
) => {
  if (client == undefined) {
    setTimeout(() => {
      client.on('ReceiveMessage', callback);
      // console.log('Added listener');
    }, 500);
  } else {
    client.on('ReceiveMessage', callback);
    // console.log('Added listener');
  }

  return client;
};

export const removeMessageListener = (
  callback: (sender: string, messages: string) => void
) => {
  client.off('ReceiveMessage', callback);
  console.log('Removed listener');
  return client;
};

export const sendMessage = (
  content: string,
  sender: string,
  recipient?: string
): Promise<void> => {
  console.log(
    `content: ${content}, sender: ${sender}, recipient: ${recipient}`
  );
  return client?.invoke('SendMessage', sender, recipient, content);
};
