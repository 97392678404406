import './LoadingSpinner.scss';

import React from 'react';

export default function LoadingSpinner() {
  return (
    // <div>
    //   <Spinner className="spinner" />
    // </div>
    <div className="spinner">
      <div className="pong-loader"></div>
    </div>
  );
}
