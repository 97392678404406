import { FC } from 'react';
import { FacebookIcon } from 'react-share';

type Props = {
  content: string;
  shareUrl: string;
};

export const FacebookShareButton: FC<Props> = ({ shareUrl }) => {
  // Only for DEV environment so as to not share localhost urls
  const url = shareUrl.includes('localhost')
    ? 'https://connectsocial.online/'
    : shareUrl;

  const width = 500;
  const height = 500;
  const top = (window.innerHeight - height) / 2;
  const left = (window.innerWidth - width) / 2;

  const handlePress = () => {
    window.self.open(
      encodeURI(
        `https://www.facebook.com/sharer/sharer.php?u=${url}&src=sdkpreparse`
      ),
      '_blank',
      `modal=yes,width=${width},height=${height},top=${top},left=${left}`
    );
  };

  return <FacebookIcon onClick={handlePress} round />;
};
