import { useEffect, useState } from 'react';

import { UserPost, UserPostPaginated } from '../models/UserPostModel';
import { initUserApi } from '../utils/userUtils';

export const useFetchUserPosts = (userId: string, pageNumber: number) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [userPosts, setUserPosts] = useState<UserPost[] | null>(null);
  const [hasMore, setHasMore] = useState(false);

  useEffect(() => {
    setUserPosts(null);
  }, [userId]);

  useEffect(() => {
    setLoading(true);
    setError(false);

    if (userId && userId !== '') {
      initUserApi()
        .get<UserPostPaginated>(`/post/${userId}/${pageNumber}`)
        .then((res) => {
          setUserPosts((prevPosts) => {
            if (prevPosts === null) {
              return res.data.posts;
            } else {
              return [...new Set([...prevPosts, ...res.data.posts])];
            }
          });
          setHasMore(res.data.pages >= pageNumber);
          setLoading(false);
        })
        .catch((e) => {
          console.log(e);
          setError(true);
          setLoading(false);
        });
    }
  }, [pageNumber, userId]);

  return { loading, error, userPosts, hasMore };
};
