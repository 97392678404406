import './../styles/views/Forum.scss';

import { Typography } from '@material-ui/core';
import React, { useCallback, useRef, useState } from 'react';
import { AiOutlinePlus } from 'react-icons/ai';
import { useHistory } from 'react-router-dom';
import useSWR, { mutate } from 'swr';

import { ConSearchBar } from '../components/feature/forum/ConSearchBar/ConSearchBar';
import { CreateForumDialog } from '../components/feature/forum/CreateForumDialog';
import { PostCard } from '../components/feature/forum/PostCard/PostCard';
import LoadingSpinner from '../components/feature/shared/LoadingSpinner/LoadingSpinner';
import { ShareDialog } from '../components/feature/sharing/ShareDialog';
import { usePopularPost } from '../hooks/usePopularPost';
import { Post } from '../models/PostModel';
import { Me } from '../models/UserModel';
import { initForumApi } from '../utils/forumUtils';

export default function Forum() {
  const history = useHistory();
  const [pageSize, setPageSize] = useState(5);
  const [pageNumber, setPageNumber] = useState(1);
  const [createForumDialogOpen, setCreateForumDialogOpen] = useState(false);
  const { loading, error, posts, hasMore } = usePopularPost(
    pageNumber,
    pageSize
  );

  const { data: me, error: meError } = useSWR<Me>(
    `${process.env.REACT_APP_API_ENDPOINT_USER}user/me`
  );

  const [shareDialogOpen, setShareDialogOpen] = useState(false);
  const [postToShare, setPostToShare] = useState<Post | undefined>();

  const onPostLike = (id: string) => {
    initForumApi()
      .post(`/post/${id}/like`)
      .then(() => {
        mutate(`${process.env.REACT_APP_API_ENDPOINT_USER}user/me`);
      });
  };

  const createForum = (
    name: string,
    iconUrl: string,
    imageUrl: string,
    description: string,
    tags: number[]
  ) => {
    initForumApi()
      .post(`/forum`, {
        name: name,
        iconUrl: iconUrl,
        imageUrl: imageUrl,
        description: description,
        tags: tags,
      })
      .then((res) => {
        setCreateForumDialogOpen(false);
        history.push(`/forum/${res.data.id}`);
      })
      .catch((e) => console.log(e));
  };

  /*
    next 20 lines of code creates the infinity scroll feeling.
  */
  const observer = useRef<IntersectionObserver>();
  const lastPostElementRef = useCallback(
    (node) => {
      if (loading) return;
      if (observer.current) {
        observer.current;
      }

      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setPageNumber((prevPageNumber) => prevPageNumber + 1);
        }
      });

      if (node) {
        observer.current.observe(node);
      }
    },
    [loading, hasMore]
  );

  return (
    <div className="flex flex-col items-center w-full h-full gap-3">
      <Typography variant="h3" className="py-5">
        Forum
      </Typography>

      <div className="w-full md:w-4/5 lg:w-full xl:w-4/5 flex gap-3">
        <ConSearchBar />
        <div
          className="w-1/5 flex justify-center items-center rounded-lg bg-primary-light hover:bg-opacity-50"
          onClick={() => setCreateForumDialogOpen(true)}
        >
          <button className="flex justify-center items-center gap-3 outline-none focus:outline-none">
            <p className="text-sm hidden md:flex">Create forum</p>
            <AiOutlinePlus className="text-xl" />
          </button>
        </div>
      </div>
      <CreateForumDialog
        open={createForumDialogOpen}
        handleCreateForum={createForum}
        handleClose={() => setCreateForumDialogOpen(false)}
        me={me ?? null}
      />

      <div className="flex flex-col w-full md:w-4/5 lg:w-full xl:w-4/5">
        {posts?.map((post, index) => {
          if (posts.length === index + 1) {
            return (
              <PostCard
                key={post.id}
                lastElRef={lastPostElementRef}
                post={post}
                isLikingPost={me?.likedForumPosts.includes(post.id) ?? false}
                onLikePost={onPostLike}
                onClickShare={() => {
                  setPostToShare(post);
                  setShareDialogOpen(true);
                }}
              />
            );
          } else {
            return (
              <PostCard
                post={post}
                key={post.id}
                isLikingPost={me?.likedForumPosts.includes(post.id) ?? false}
                onLikePost={onPostLike}
                onClickShare={() => {
                  setPostToShare(post);
                  setShareDialogOpen(true);
                }}
              />
            );
          }
        })}
        <ShareDialog
          open={shareDialogOpen}
          content={
            'Wow look at this cool post i found on connectsocial.online\n\n'
          }
          // Has to code URL like this since the current url is /profile and not /displayname/tag
          shareUrl={`https://connectsocial.online/forum/post/${
            postToShare?.id ?? ''
          }`}
          handleClose={() => setShareDialogOpen(false)}
        />
        <div className="flex w-full justify-center items-center">
          {loading && <LoadingSpinner />}
        </div>
      </div>

      <div>{error && 'Some Error happend'}</div>
      <div>
        {!hasMore && (
          <Typography variant="h6">
            Go outside, you have reached the end of the internet.
          </Typography>
        )}
      </div>
    </div>
  );
}
