import axios from 'axios';
import jwt_decode from 'jwt-decode';

export type DecodedTokenType = {
  UserID: string;
  iss: string;
  aud: string;
  exp: number;
  nbf: number;
  name: string;
};

export const initAuthApi = () => {
  const headers: HeadersInit = {
    Accept: 'application/json',
  };

  const client = axios.create({
    baseURL: process.env.REACT_APP_API_ENDPOINT_AUTH,
    timeout: 31000,
    headers: headers,
  });
  return client;
};

export const checkAuth = () => {
  const token = localStorage.getItem('con_token');

  if (!token) {
    return false;
  }

  try {
    const decoded: DecodedTokenType = jwt_decode(token);

    if (decoded.exp < new Date().getTime() / 1000) {
      initAuthApi()
        .get('Refresh', { withCredentials: true })
        .then((res) => {
          localStorage.setItem('con_token', res.data);
        })
        .catch((e) => {
          console.log(e);
          logout();
          initAuthApi().get('Logout');
        });

      return true;
    }
  } catch (e) {
    return false;
  }

  return true;
};

export const logout = () => {
  localStorage.removeItem('con_userIsSetup');
  localStorage.removeItem('con_token');
  localStorage.clear();
  initAuthApi().get('Logout', { withCredentials: true });
};
